import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  CircularProgress,
  AlertColor,
} from '@mui/material'
import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import InventoryIcon from '@mui/icons-material/Inventory'
import { Uploader } from 'rsuite'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { DocumentListReq, Results } from 'reduxStore/subcription/Document/GetDocument/Model'
import { fetchDocumentList } from 'reduxStore/subcription/Document/GetDocument/documentListSlice'
import { hideDocument } from 'reduxStore/subcription/Document/hideDocument/hideDocumentSlice'
import { hideDocumentReq } from 'reduxStore/subcription/Document/hideDocument/Model'
import url from 'APIConfig'
import './uploader.css'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { renderApplogieDate } from 'components/Utility'
interface Upload {
  name: string
  account: string
}

export default function DocumentsList() {
  const Token = localStorage.getItem('token')
  // Get the userId param from the URL.
  let { id } = useParams()
  const dispatch = useAppDispatch()
  const documentListSource = useAppSelector((state) => state.documentList)
  const hideDocumentList = useAppSelector((state) => state.hideDocument)
  const [documentViewModalOpen, setdocumentViewModalOpen] = React.useState(false)
  const [hideId, setHideId] = React.useState(0)
  const handleHideDocumentClose = () => {
    setdocumentViewModalOpen(false)
  }
  const hideDocumentPopup = async () => {
    let hidebody = {
      id: hideId,
      token: Token!,
    } as hideDocumentReq
    await dispatch(hideDocument(hidebody))
    let reqBody = {
      documentId: id,
      token: Token!,
    } as DocumentListReq
    await dispatch(fetchDocumentList(reqBody))
    // setHideSuccessmsg(true)
    await setdocumentViewModalOpen(false)
  }
  const handleDocumentModalClose = () => {
    setdocumentViewModalOpen(false)
  }
  const handleHidecommentPopup = (id: number) => {
    setdocumentViewModalOpen(true)
    setHideId(id)
  }
  const [successmsg, setSuccessmsg] = React.useState(false)
  const successFieldMsg = 'Document Upload Successfully Completed !'
  const handleSuccess = () => {
    setOpen(false)
    setSuccessmsg(false)
  }

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [openHideMsg, setOpenHideMsg] = React.useState('')

  const handleHideSuccess = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenHideMsg('')
  }

  React.useEffect(() => {
    if (hideDocumentList?.succssMessage !== '') {
      setAPIMsgType('success')
      setOpenHideMsg(hideDocumentList?.succssMessage)
    }
    if (hideDocumentList?.error?.message !== '') {
      if (hideDocumentList?.error?.message) {
        setAPIMsgType('error')
        setOpenHideMsg(hideDocumentList?.error?.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideDocumentList])

  let headers = {
    Authorization: `Token ${Token!}`,
  }

  let uploadUrl = url.backEndURL + '/api/accountdocument'

  let uploadbody = {
    account: JSON.stringify({
      type: 'Account',
      id: id,
    }),
  } as Upload

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  function openUrl(url: string) {
    window.open(url)
  }

  React.useEffect(() => {
    let reqBody = {
      documentId: id,
      token: Token!,
    } as DocumentListReq
    dispatch(fetchDocumentList(reqBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, Token])

  React.useEffect(() => {}, [documentListSource])

  return (
    <Card sx={{ minHeight: '250px' }}>
      <CardHeader
        avatar={<InventoryIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Stack direction={'row'}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Documents
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  <Chip
                    label={
                      'results' in documentListSource.documentList
                        ? documentListSource.documentList.results.length
                        : 0
                    }
                  ></Chip>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={'row'}>
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Add Document
              </Button>
            </Stack>
          </Stack>
        }
      ></CardHeader>
      {documentListSource.loading === true ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress color="inherit" size={30} />
          </Grid>
        </Grid>
      ) : null}

      <CardContent sx={{ maxHeight: '160px', overflow: 'auto' }}>
        {'results' in documentListSource.documentList ? (
          documentListSource.documentList.results.length > 0 ? (
            documentListSource.documentList.results.map((item: Results) => (
              <Box m={1} key={item.id}>
                <Alert
                  icon={false}
                  sx={{
                    backgroundColor: '#08687D',
                    color: 'white',
                    '& .MuiAlert-icon': {
                      color: 'white',
                    },
                  }}
                  onClose={() => {
                    handleHidecommentPopup(item.id)
                  }}
                >
                  <AlertTitle sx={{ color: 'white' }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      sx={{
                        '&:hover': { cursor: 'pointer' },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                      }}
                      onClick={() => {
                        openUrl(item.document)
                      }}
                    >
                      {item.name}
                    </Typography>
                  </AlertTitle>
                  On &nbsp;
                  {renderApplogieDate(item.create_date)}
                </Alert>
              </Box>
            ))
          ) : (
            <Grid container direction="row" justifyContent="center" alignItems="center" pt={1}>
              <Grid item>
                <Typography variant="subtitle1">No content available</Typography>
              </Grid>
            </Grid>
          )
        ) : null}

        {/* File Upload */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Typography variant="h6">
                  <b>Add Document:</b>
                </Typography>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Box
              style={{
                textAlign: 'center',
                border: '2px #c4c2c2 dotted',
                borderRadius: '2px',
                width: '375px',
              }}
              p={2}
            >
              <Stack>
                <Uploader
                  action={uploadUrl}
                  data={uploadbody}
                  name="document"
                  accept=""
                  method="POST"
                  headers={headers}
                  onSuccess={() => {
                    setSuccessmsg(true)
                    let reqBody = {
                      documentId: id,
                      token: Token!,
                    } as DocumentListReq
                    dispatch(fetchDocumentList(reqBody))
                  }}
                  shouldUpload={(file) => {
                    uploadbody.name = file.name || ''
                    return true
                  }}
                  draggable
                >
                  <div
                    style={{
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button type="button">
                      <FileUploadIcon />
                    </Button>
                    <span>Drop file here for upload</span>
                  </div>
                </Uploader>
              </Stack>
              {successmsg === true ? (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert
                    severity="success"
                    onClose={() => {
                      handleSuccess()
                    }}
                  >
                    <AlertTitle>Success</AlertTitle>
                    <strong>{successFieldMsg}</strong>
                  </Alert>
                </Stack>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" pr={3}>
              <Grid item>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  style={{ backgroundColor: '#dcdcdc' }}
                >
                  <Typography sx={{ color: '#000', fontSize: '15px' }}>Close</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        {/* Hide Document */}
        <Dialog
          open={documentViewModalOpen}
          onClose={handleHideDocumentClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <DialogContentText>
              <strong>Are you sure you want to hide this document for all users?</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => hideDocumentPopup()}>
              {hideDocumentList.loading === true ? (
                <Stack direction="row" spacing={1}>
                  <Typography>Hide Document</Typography>
                  <Box pl={1}>
                    <CircularProgress color="inherit" size={18} />
                  </Box>
                </Stack>
              ) : (
                'Hide Document'
              )}
            </Button>
            <Button variant="outlined" onClick={handleDocumentModalClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <ApplogieSnackbar msg={openHideMsg} type={apiMsgType} alertClose={handleHideSuccess} />
      </CardContent>
      <DialogActions></DialogActions>
    </Card>
  )
}
