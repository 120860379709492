import React from 'react'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Link } from '@mui/material'
import { useAppDispatch } from 'reduxStore/hooks'
import { toggleMicrosoftLicenseUserDialogState, updateMicrosoftLicenseUser } from 'reduxStore/account/microsoft/MicrosoftUserLicenseSlice'
export function MicrosoftTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'sku_name',
      headerName: 'Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Link
            component="button"
            variant="subtitle2"
            underline="none"
            onClick={() => {
              dispatch(toggleMicrosoftLicenseUserDialogState(true))
              dispatch(updateMicrosoftLicenseUser(params.row.sku_name))
            }}
          >
            <b> {params.row.sku_name}</b>
          </Link>
        )
      },
    },
    {
      field: 'purchased_licenses',
      headerName: 'Purchased Licenses',
      sortable: true,
      hideable: false,
      minWidth: 150,
      align: 'center',
    },
    {
      field: 'consumed_licenses',
      headerName: 'Assigned',
      sortable: true,
      hideable: false,
      minWidth: 80,
      align: 'center',
    },
  ]
  return columns
}
