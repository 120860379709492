import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { EditCompanyUserMainBody, EditCompanyUserResponse } from './editCompanyUserModel'

type InitialState = {
  loading: boolean
  editUserRes: EditCompanyUserResponse
  errorMessage: string
  successMessage: string
}
const initialState: InitialState = {
  loading: false,
  editUserRes: {} as EditCompanyUserResponse,
  successMessage: '',
  errorMessage: '',
}

export const editCompanyUserApi = createAsyncThunk<
  EditCompanyUserResponse,
  EditCompanyUserMainBody
>(
  'editCompanyUserApi/editCompanyUserApi',
  async (payload: EditCompanyUserMainBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + `/api/users/${payload.id}`, payload.body, { headers })
      .then((editUserRes) => editUserRes.data)
      .catch((error) => rejectWithValue(error.editUserRes.data[0].detail))
  },
)

const editCompanyUserSlice = createSlice({
  name: 'editCompanyUserApi',
  initialState,
  reducers: {
    editCompanyUserClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    editCompanyUserClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.editUserRes = {} as EditCompanyUserResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editCompanyUserApi.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      editCompanyUserApi.fulfilled,
      (state, action: PayloadAction<EditCompanyUserResponse>) => {
        state.loading = false
        state.editUserRes = action.payload
        state.successMessage = 'User details has been updated successfully'
        state.errorMessage = ''
      },
    )
    builder.addCase(editCompanyUserApi.rejected, (state, action) => {
      state.loading = false
      state.editUserRes = {} as EditCompanyUserResponse
      state.errorMessage = String(action.payload!)
    })
  },
})
export const { editCompanyUserClearMsg, editCompanyUserClearRes } = editCompanyUserSlice.actions
export default editCompanyUserSlice.reducer
