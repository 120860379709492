import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRowParams,
} from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppSelector } from 'reduxStore/hooks'
import { checkReturnValue } from 'components/Utility'
import { CommentsList } from 'reduxStore/comments/commentList/Model'
import dayjs from 'dayjs'
import { ShowCommentDetailsToggle } from './ShowCommentDetailsToggle'

interface VendorSpendChart {
  date: string
  spend: number
  budget: number
  year: string
  modifiedYear: string
  id?: number
  renewalNo?: number
  commentId?: number
  height?: number
}

type VendorRenewalListProps = {
  enableCommentListDialog: boolean
  updateCommentDialogState: (value: boolean) => void
  selectedComment: VendorSpendChart
  updateCommentItem: (value: VendorSpendChart) => void
}
export default function CommentList({
  enableCommentListDialog,
  updateCommentDialogState,
  selectedComment,
  updateCommentItem,
}: VendorRenewalListProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [open, setOpen] = React.useState(false)
  useEffect(() => {
    enableCommentListDialog ? setOpen(true) : setOpen(false)
  }, [enableCommentListDialog])
  const handleClose = () => {
    setOpen(false)
    updateCommentDialogState(false)
    updateCommentItem({} as VendorSpendChart)
  }
  const commentsListRes = useAppSelector((state) => state.commentsListRes)

  const [commentList, setCommentList] = useState([] as any[])
  useEffect(() => {
    if (
      checkReturnValue(selectedComment) !== 'Not Set' &&
      checkReturnValue(commentsListRes?.commentsList?.results) !== 'Not Set'
    ) {
      if (checkReturnValue(selectedComment?.modifiedYear) !== 'Not Set') {
        let tempCommentList = commentsListRes?.commentsList?.results.filter(
          (o) =>
            o.month === monthNames.indexOf(selectedComment?.modifiedYear!.slice(0, -5))! + 1 &&
            o.year === Number(selectedComment?.year),
        )
        let tempCommentArr = tempCommentList.map((item: CommentsList) => {
          return {
            date: dayjs(new Date(item.year + '/' + item.month + '/01')).format('ddd DD MMM YYYY'),
            id: item?.id,
            month: monthNames[item?.month - 1].slice(0, 3),
            year: String(item.year),
            accountName:
              checkReturnValue(item?.account?.vendor?.name) !== 'Not Set' &&
              item?.account?.vendor?.name,
            accountId: item?.account?.id,
            comment: checkReturnValue(item?.comment),
            accountFullName:
              checkReturnValue(item?.account?.vendor?.name) !== 'Not Set' &&
              item?.account?.vendor?.name + ' - ' + item?.account?.account_number,
            dateStamp: `On ${
              checkReturnValue(item?.create_date) !== 'Not Set' &&
              dayjs(item?.create_date).format('ddd MMM DD YYYY')
            } by ${item?.owner?.user?.first_name} ${item?.owner?.user?.last_name}`,
          }
        })
        setCommentList(tempCommentArr)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsListRes, selectedComment])

  const columns: GridColDef[] = [
    {
      field: 'month',
      headerName: 'Month',
      sortable: true,
      hideable: true,
      width: 110,
      align: 'left',
    },
    {
      field: 'year',
      headerName: 'Year',
      sortable: true,
      hideable: true,
      width: 110,
      align: 'left',
    },
    {
      field: 'accountName',
      headerName: 'Account',
      sortable: true,
      hideable: false,
      minWidth: 180,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/detail/${params.row.accountId}`)
            }}
          >
            {params.row.accountName}
          </Button>
        )
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      sortable: true,
      hideable: true,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <ShowCommentDetailsToggle id={params.id} value={params.value} />
      ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  function DetailPanelContent({ row }: { row: any }) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        py={2}
        sx={{ background: 'transparent' }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Typography variant="body1">Account:</Typography>
                  <Typography variant="body1">Comment:</Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Typography variant="body1">{row.accountFullName}</Typography>
                  <Typography variant="body1">{row.comment}</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Typography variant="body1" sx={{ paddingLeft: 1 }}>
              {row.dateStamp}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>View list of comments</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: 'calc(100vh - 250px)' }}>
          <DataGridPremium
            columns={columns}
            rows={commentList}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            hideFooter
            disableRowGrouping
            disableAggregation
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No Renewals are available' }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
