import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,

} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CloseIcon from '@mui/icons-material/Close'
import {
  selectedSubscriptionPaymentDetails,
  updatePaymentClearMsg,
  updatePaymentClearRes,
} from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'
import { ModifiedPaymentList } from '../../Model'
import { checkReturnValue } from 'components/Utility'
import { detectWrongPayment, detectWrongPaymentDialogState } from 'reduxStore/account/detectWrongPayment/detectWrongPaymentSlice'
import { Result as subVendorName } from 'reduxStore/subcription/Active/Model'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'
import { WrongPaymentBody, WrongPaymentReq } from 'reduxStore/account/detectWrongPayment/detectWrongPaymentModel'
interface paymentList {
  label: string
  id: number
}
type Props = {
  selectedPaymentTransItem?: ModifiedPaymentList
  transferSelectedItem: (value: ModifiedPaymentList) => void
  transferWrongPaymentDialogEnable?: boolean
  transferWrongPaymentDialogUpdateState: (value: boolean) => void
}

export default function TransferWrongPayment({
  selectedPaymentTransItem,
  transferSelectedItem,
  transferWrongPaymentDialogEnable,
  transferWrongPaymentDialogUpdateState,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [selectedOwner, setSelectedOwner] = React.useState<paymentList | null>(null)
  function handleLimitChange(value: paymentList | null) {
    setSelectedOwner(value)
  }
  const [openAlert, setAlert] = useState(false)
  const closeAlert = () => {
    setAlert(false)
  }

  const [transferWrongPaymentDialogOpen, setTransferWrongPaymentDialogOpen] = useState(false)
  useEffect(() => {
    setTransferWrongPaymentDialogOpen(transferWrongPaymentDialogEnable!)
    dispatch(fetchSubscription(Token!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferWrongPaymentDialogEnable])

  const [paymentId, setPaymentId] = useState('')
  useEffect(() => {
    setPaymentId(checkReturnValue(selectedPaymentTransItem?.id!) !== 'Not Set' ? String(selectedPaymentTransItem?.id) : '')
  }, [selectedPaymentTransItem])

  //close Dialog
  const closeTransferPaymentDialog = () => {
    setPaymentId('')
    transferWrongPaymentDialogUpdateState(false)
    transferSelectedItem({} as ModifiedPaymentList)
    dispatch(updatePaymentClearMsg())
    dispatch(updatePaymentClearRes())
    dispatch(detectWrongPaymentDialogState(false))
    dispatch(selectedSubscriptionPaymentDetails({} as any))
    setSubscriptionVendorList([])
  }
  const [subscriptionVendorList, setSubscriptionVendorList] = useState([] as any[])

  // Call Store
  const subsValue = useAppSelector((state) => state.subscription)
  const transferPaymentRes = useAppSelector((state) => state.DetectWrongPayment)

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    if (checkReturnValue(subsValue?.subscriptions?.results) !== 'Not Set') {
      if (subsValue?.subscriptions?.results.length > 0) {
        let vendorNameArr = subsValue.subscriptions.results.map((item: subVendorName) => {
          return {
            label: item.vendor_name!,
            id: item.vendor_id,
          }
        })
        setSubscriptionVendorList(vendorNameArr)
      } else setSubscriptionVendorList([] as any[])
    } else setSubscriptionVendorList([] as any[])
  }, [subsValue])

  let transferPaymentSubBody = {
    payment_id: Number(paymentId),
    right_vendor_id: selectedOwner?.id
  } as WrongPaymentBody

  let transferPaymentMainBody = {
    updateWrongBody: transferPaymentSubBody,
    token: Token!,
  } as WrongPaymentReq

  // Api call for tranfer payment...
  const Transferpayment = async () => {
    if (selectedOwner) {
      await dispatch(detectWrongPayment(transferPaymentMainBody))
    }
    else {
      setAlert(true)
    }
  }
  return (
    <Dialog open={transferWrongPaymentDialogOpen} onClose={closeTransferPaymentDialog} maxWidth="sm" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction={'row'}>
            <Typography variant="h6" fontWeight="bold">
              Choose Correct Vendor
            </Typography>
          </Stack>
          <Stack direction={'row'}>
            <IconButton aria-label="delete" onClick={closeTransferPaymentDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={openAlert} timeout="auto" unmountOnExit>
          <Box my={2}>
            <Alert severity="error" onClose={closeAlert}>
              <b>Error:</b> Please select the correct vendor from vendor list.
            </Alert>
          </Box>
        </Collapse>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            <Typography>Please modify the payment mapping and select the correct vendor for processing.</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              id="transfer-payment"
              value={selectedOwner}
              options={subscriptionVendorList}
              onChange={(_event, value: any) => handleLimitChange(value!)}
              renderInput={(params) => (
                <TextField {...params} label="Select Vendor" variant="standard" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => Transferpayment()}
          disabled={transferPaymentRes.loading || selectedOwner === null}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>Transfer</Typography>
            </Grid>
            {transferPaymentRes.loading ? (
              <Grid item>
                <CircularProgress color="inherit" size={18} />
              </Grid>
            ) : null}
          </Grid>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
