import { MonthList, VisibilityList, YearList } from './Model'

export const visibilityList = [
  { label: 'Overview', value: 10 },
  { label: 'Chart', value: 20 },
  { label: 'Card', value: 30 },
] as VisibilityList[]

export const yearList = [
  { label: '2024', value: 2024 },
  { label: '2023', value: 2023 },
  { label: '2022', value: 2022 },
  { label: '2021', value: 2021 },
] as YearList[]

export const monthList = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
] as MonthList[]
