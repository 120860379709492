import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import ChatIcon from '@mui/icons-material/Chat'
import { useParams } from 'react-router-dom'
import { apiBody, Result } from 'reduxStore/comments/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchComments } from 'reduxStore/comments/commentsSlice'
import { addCommentClearRes } from 'reduxStore/comments/addComment/addCommentSlice'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { hideCommentClearRes } from 'reduxStore/comments/Hide/hideCommentSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import AddComment from './AddComment'
import { HideComment } from './HideComment'
import { renderApplogieDate } from 'components/Utility'

export default function CommentsList() {
  const [open, setOpen] = React.useState(false)
  const updateDialogState = (passedVal: boolean) => {
    setOpen(passedVal)
  }
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const commentsValue = useAppSelector((state) => state.comments)
  const addCommentRes = useAppSelector((state) => state.addComment)
  const hideCommentRes = useAppSelector((state) => state.hideComment)
  const { id } = useParams()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')

  React.useEffect(() => {
    let commentListPayload = {
      id: String(id),
      token: Token!,
    } as apiBody
    dispatch(fetchComments(commentListPayload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])
  //For Add Comment
  React.useEffect(() => {
    if (addCommentRes?.succssMessage !== '') {
      setAPIMsgType('success')
      setAddCommentMsg(addCommentRes?.succssMessage)
      let commentListPayload = {
        id: String(id),
        token: Token!,
      } as apiBody
      dispatch(fetchComments(commentListPayload))
    }
    if (addCommentRes?.error?.message !== '') {
      if (addCommentRes?.error?.message) {
        setAPIMsgType('error')
        setAddCommentMsg(addCommentRes?.error?.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentRes])

  const [addCommentMsg, setAddCommentMsg] = React.useState('')
  const closeAddCommentMessage = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(addCommentClearRes())
      setAddCommentMsg('')
    }
  }

  //For Hide Comment
  const [hideCommentMsg, setHideCommentMsg] = React.useState('')
  React.useEffect(() => {
    if (hideCommentRes?.successMessage !== '') {
      let commentListPayload = {
        id: String(id),
        token: Token!,
      } as apiBody
      dispatch(fetchComments(commentListPayload))
    }
    if (hideCommentRes?.errorMessage !== '') {
      setAPIMsgType('error')
      setHideCommentMsg(hideCommentRes?.errorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCommentRes])

  const closeHideCommentMessage = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(hideCommentClearRes())
      setHideCommentMsg('')
    }
  }

  const [enableHideCommentDialog, setEnableHideCommentDialog] = React.useState(false)
  const closeHideCommentDialog = (passedVal: boolean) => {
    setEnableHideCommentDialog(passedVal)
  }

  const [commentId, setCommentId] = React.useState('')
  const selectCommentForHide = (id: string) => {
    setCommentId(id)
    setEnableHideCommentDialog(true)
  }
  const resetSelectedCommentIDOnDialogClose = (passedId: string) => {
    setCommentId(passedId)
  }
  return (
    <Box>
      <Card sx={{ minHeight: '250px' }}>
        <CardHeader
          avatar={<ChatIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Comments
                    </Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Chip
                      label={
                        'count' in commentsValue.comments
                          ? String(commentsValue.comments.count)
                          : null
                      }
                    ></Chip>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  Add Comment
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent sx={{ maxHeight: '175px', overflow: 'auto' }}>
          {commentsValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box>
              {'results' in commentsValue.comments ? (
                commentsValue.comments.results.length > 0 ? (
                  commentsValue.comments.results.map((item: Result) => (
                    <Box m={1} key={item.id}>
                      <Alert
                        sx={{
                          backgroundColor: '#08687D',
                          color: 'white',
                          '& .MuiAlert-icon': {
                            color: 'white',
                          },
                        }}
                        icon={<ArrowForwardIosIcon />}
                        onClose={() => {
                          selectCommentForHide(String(item.id))
                        }}
                      >
                        <AlertTitle sx={{ color: 'white' }}>{item.comment}</AlertTitle>
                        On &nbsp;
                        {renderApplogieDate(item.create_date!)}
                        &nbsp; by &nbsp;
                        {item.owner.user.first_name + ' ' + item.owner.user.last_name}
                      </Alert>
                    </Box>
                  ))
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    pt={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle1">No content available</Typography>
                    </Grid>
                  </Grid>
                )
              ) : null}
            </Box>
          )}
        </CardContent>
      </Card>

      {/* dialog for Add  comment */}
      {open && <AddComment enableDialog={open} updateDialogState={updateDialogState} />}
      {/* dialog for close  comment */}

      <HideComment
        enableHideCommentDialog={enableHideCommentDialog}
        enableHideCommentDialogUpdateState={closeHideCommentDialog}
        selectedCommentId={String(commentId)}
        selectedDialogUpdatedState={resetSelectedCommentIDOnDialogClose}
      />
      <ApplogieSnackbar msg={addCommentMsg} type={apiMsgType} alertClose={closeAddCommentMessage} />

      <ApplogieSnackbar
        msg={hideCommentMsg}
        type={apiMsgType}
        alertClose={closeHideCommentMessage}
      />
    </Box>
  )
}
