import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import { MicrosoftUsageDetailsModelReq } from 'reduxStore/users/microsoftUsage/Model'
import { fetchMicrosoftUsageDetailsById } from 'reduxStore/users/microsoftUsage/microsoftUsageSlice'
import { MicrosoftUsageList } from '../../userDetailsModel'
import { checkReturnValue, renderApplogieDate } from 'components/Utility'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
export default function MicrosoftUsage() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const microsoftUsageById = useAppSelector((state) => state.microsoftUsageById)
  const [microsoftUsageList, setMicrosoftUsageList] = useState([] as MicrosoftUsageList[])
  let microsoftUsageDetailsByIdBody = {
    userId: String(id),
    token: Token,
  } as MicrosoftUsageDetailsModelReq

  useEffect(() => {
    dispatch(fetchMicrosoftUsageDetailsById(microsoftUsageDetailsByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  const [assignedProd, setAssignProd] = useState([] as any[])
  useEffect(() => {
    if (checkReturnValue(microsoftUsageById?.userDetails?.results) !== 'Not Set') {
      let ms_data = []
      for (var i = 0; i < microsoftUsageById.userDetails.results.length; i++) {
        ms_data.push({
          id: 1,
          product: 'Exchange',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].exchange_last_activity_date,
          ),
          license_assign_date:
            microsoftUsageById.userDetails.results[i].exchange_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].exchange_has_license,
        })
        ms_data.push({
          id: 2,
          product: 'One Drive',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].one_drive_last_activity_date,
          ),
          license_assign_date:
            microsoftUsageById.userDetails.results[i].one_drive_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].one_drive_has_license,
        })
        ms_data.push({
          id: 3,
          product: 'Sharepoint',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].sharepoint_last_activity_date,
          ),
          license_assign_date:
            microsoftUsageById.userDetails.results[i].sharepoint_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].sharepoint_has_license,
        })
        ms_data.push({
          id: 4,
          product: 'Skype for Business',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].skype_last_activity_date,
          ),
          license_assign_date: microsoftUsageById.userDetails.results[i].skype_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].skype_has_license,
        })
        ms_data.push({
          id: 5,
          product: 'Yammer',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].yammer_last_activity_date,
          ),
          license_assign_date: microsoftUsageById.userDetails.results[i].yammer_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].yammer_has_license,
        })
        ms_data.push({
          id: 6,
          product: 'Teams',
          last_activity_date: renderApplogieDate(
            microsoftUsageById.userDetails.results[i].teams_last_activity_date,
          ),
          license_assign_date: microsoftUsageById.userDetails.results[i].teams_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].teams_has_license,
        })
      }
      setMicrosoftUsageList(ms_data)
    } else setMicrosoftUsageList([] as any[])
    let mainRes =
      microsoftUsageById?.userDetails?.results !== undefined
        ? microsoftUsageById?.userDetails?.results[0]?.assigned_products
        : ''
        if (mainRes && mainRes !== '') {
          const stringWithoutBrackets = mainRes.replace(/\[|\]/g, '')
          // eslint-disable-next-line no-useless-escape
          const stringWithoutQuotes = stringWithoutBrackets.replace(/[\"']/g, '')
          // Split by comma and create an array
          const finalArray = stringWithoutQuotes.split(', ')
          setAssignProd(finalArray)
        } else setAssignProd([] as any[])
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microsoftUsageById])

  const columns: GridColDef[] = [
    {
      field: 'product',
      headerName: 'Product',
      sortable: true,
      hideable: false,
      width: 280,
      align: 'left',
    },
    {
      field: 'last_activity_date',
      headerName: 'Last Activity',
      sortable: true,
      hideable: false,
      minWidth: 280,
      align: 'left',
    },
    {
      field: 'license_assign_date',
      headerName: 'license Assign',
      sortable: true,
      hideable: true,
      minWidth: 180,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null ? renderApplogieDate(params.row.renewalDate!) : null}
        </React.Fragment>
      ),
    },
    {
      field: 'has_license',
      headerName: 'Has License?',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>{params.row.has_license ? 'True' : 'False'}</React.Fragment>
      ),
    },
  ]

  return (
    <Card>
      <CardHeader
        avatar={<SwapVerticalCircleIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Microsoft Usage
              </Typography>
            </Grid>
            <Grid item>
              <Chip label={microsoftUsageList.length} />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  dispatch(fetchMicrosoftUsageDetailsById(microsoftUsageDetailsByIdBody))
                }
              >
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Box sx={{ width: '100%', bgcolor: '#F2F2F2' }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            p={1}
            spacing={1}
          >
            <Grid item md={6}>
                <Typography variant="body2">Assigned Products</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873'}}>
                    {assignedProd.length > 0 ? (
                    assignedProd.map((item: any) => (
                      <Box sx={{p: 0.5}}>
                        <Chip label={item}/>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Not Set
                    </Typography>
                  )}
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="body2">Products</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                  {microsoftUsageById?.userDetails?.results !== undefined 
                ? microsoftUsageById?.userDetails?.results[0]?.deleted_date
                : 'Not Set'}
                </Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="body2">Deleted Date:</Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                {microsoftUsageById?.userDetails?.results !== undefined
                ? microsoftUsageById?.userDetails?.results[0]?.deleted_date
                : 'Not Set'}
                </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ pt: 2 }}>
          {microsoftUsageById.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ height: '33vh' }}>
              <DataGridPremium
                sx={{
                  '@media print': {
                    '.MuiDataGrid-main': {
                      width: 'fit-content',
                      fontSize: '20px',
                      height: 'fit-content',
                      overflow: 'visible',
                    },
                    marginBottom: 100,
                  },
                  '& .MuiDataGrid-footerContainer': {
                    height: '0px',
                    minHeight: '0px',
                  },
                }}
                columns={columns}
                rows={microsoftUsageList}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'product', sort: 'asc' }],
                  },
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                slots={{
                  toolbar: MUIDDCustomToolbar,
                }}
                hideFooterRowCount
                hideFooterSelectedRowCount
                disableRowGrouping
                disableAggregation
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: 'No Microsoft Usage Details available' }}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
