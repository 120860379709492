import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { MonthList, VisibilityList } from './Model'
import { monthList, visibilityList, yearList } from './CommentsDataSet'
import { useParams } from 'react-router-dom'
import {
  AddCommentBody,
  AddCommentExtendedBody,
} from 'reduxStore/comments/addComment/addCommentModel'
import { addComment } from 'reduxStore/comments/addComment/addCommentSlice'

type Props = {
  enableDialog: boolean
  updateDialogState: (value: boolean) => void
}

export default function AddComment({ enableDialog, updateDialogState }: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const addCommentRes = useAppSelector((state) => state.addComment)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
    updateDialogState(false)
  }
  useEffect(() => {
    enableDialog !== undefined ? enableDialog && setOpen(enableDialog) : setOpen(false)
  }, [enableDialog])

  const [selectedVisibility, setSelectedVisibility] = useState<VisibilityList | null>(null)
  const selectedVisibilityChange = (passedVal: VisibilityList | null) => {
    setSelectedVisibility(passedVal)
  }

  const [selectedYear, setSelectedYear] = useState<MonthList | null>(null)
  const selectedYearChange = (passedVal: MonthList | null) => {
    setSelectedYear(passedVal)
  }

  const [selectedMonth, setSelectedMonth] = useState<MonthList | null>(null)
  const selectedMonthChange = (passedVal: MonthList | null) => {
    setSelectedMonth(passedVal)
  }
  const [comment, setComment] = React.useState('')
  const addComments = async () => {
    let commentBody = {
      year: selectedYear !== null && selectedYear?.value,
      month: selectedMonth !== null && selectedMonth?.value,
      comment: comment,
      visibility: selectedVisibility !== null && selectedVisibility?.value,
      account: id,
    } as AddCommentExtendedBody
    let mainAddCommentBody = {
      updateBody: commentBody,
      token: Token!,
    } as AddCommentBody

    if (comment !== '') {
      await dispatch(addComment(mainAddCommentBody))
    }
  }
  useEffect(() => {
    addCommentRes?.succssMessage !== '' && handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentRes])

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '700px',
          },
        },
      }}
    >
      <DialogTitle>
        <Grid id="top-row" container spacing={24}>
          <Grid item xs={9}>
            <strong> Add a comment:</strong>
          </Grid>
          <Grid item xs={3}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={4} sm={4}>
            <Autocomplete
              id="visibility"
              value={selectedVisibility}
              options={visibilityList}
              onChange={(_event: any, newValue: VisibilityList | null) => {
                selectedVisibilityChange(newValue)
              }}
              disableClearable={true as any}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField {...params} label="Visibility" variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Autocomplete
              id="year"
              value={selectedYear}
              options={yearList}
              onChange={(_event: any, newValue: MonthList | null) => {
                selectedYearChange(newValue)
              }}
              disableClearable={true as any}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Year" variant="standard" />}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Autocomplete
              id="month"
              value={selectedMonth}
              options={monthList}
              onChange={(_event: any, newValue: MonthList | null) => {
                selectedMonthChange(newValue)
              }}
              disableClearable={true as any}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option: any, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Month" variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Comment"
              type="tag"
              fullWidth
              variant="standard"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={addComments} autoFocus variant="contained">
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Typography>Add Comment</Typography>
            {addCommentRes.loading && <CircularProgress color="inherit" size={18} />}
          </Stack>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
