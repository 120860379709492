import * as React from 'react'
import { Stack, TextField, Button, Typography, Box, CircularProgress, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { fetchSearchUser } from 'reduxStore/users/searchUser/searchUserSlice'
import { SearchUserExtendedBody, SearchUserBody } from 'reduxStore/users/searchUser/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CloseIcon from '@mui/icons-material/Close';
import { ActiveUsersDetailsReq } from 'reduxStore/users/Active/Model'
import { fetchActiveuser } from 'reduxStore/users/Active/activeuserSlice'
import { InactiveUsersDetailsReq } from 'reduxStore/users/Inactive/Model'
import { fetchInactiveuser } from 'reduxStore/users/Inactive/inactiveuserSlice'
type Props = {
  tabIndex?: number | any
}

export const SearchActiveUser = ({ tabIndex }: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const searchUserListValue = useAppSelector((state) => state.userSearch)
  const [userStatus, setUserStatus] = React.useState(0)
  React.useEffect(() => {
    if (tabIndex !== undefined) {
      setUserStatus(tabIndex)
    }
  }, [tabIndex])
  const [searchUser, setSearchUser] = React.useState('')
  const handleSearchUserNameChange = (val: string) => {
    setSearchUser(val)
  }

  const cancelSearchUser = () => {
    setSearchUser("");
    if (userStatus === 0) {
    let activeUserDetailsParam = {
      pageCount: String(1),
      length: String(50),
      token: Token!,
    } as ActiveUsersDetailsReq
    dispatch(fetchActiveuser(activeUserDetailsParam))
    }
    if(userStatus === 1){
      let inactiveUserDetailsParam = {
        pageCount: String(1),
        length: String(50),
        token: Token!,
      } as InactiveUsersDetailsReq
      dispatch(fetchInactiveuser(inactiveUserDetailsParam))
    }
  }

  const searchActiveUsersItem = () => {
    if (userStatus === 0) {
      let updatedbody = {
        isActive: 'True',
        searchStr: searchUser,
      } as SearchUserExtendedBody
      let mainbody = {
        updateBody: updatedbody,
        token: Token!,
      } as SearchUserBody
      dispatch(fetchSearchUser(mainbody))
    } else {
      let updatedbody = {
        isActive: 'False',
        searchStr: searchUser,
      } as SearchUserExtendedBody
      let mainbody = {
        updateBody: updatedbody,
        token: Token!,
      } as SearchUserBody
      dispatch(fetchSearchUser(mainbody))
    }
  }
  return (
    <Stack direction={'row'} spacing={1}>
      <TextField
        id="standard-basic"
        label=""
        placeholder="Enter Search value"
        variant="standard"
        value={searchUser}
        onChange={(e) => {
          handleSearchUserNameChange(e.target.value)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') searchActiveUsersItem()
        }}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 30
          }
      }}
      InputProps={{
        endAdornment: searchUser && (
        <IconButton
          aria-label="toggle password visibility"
            onClick={() => cancelSearchUser()}
        ><CloseIcon/></IconButton>
      )
      }}
      />
      <Button
        variant="contained"
        size="small"
        startIcon={<SearchIcon />}
        onClick={() => searchActiveUsersItem()}
      >
        {searchUserListValue.loading === true ? (
          <Stack direction="row" spacing={1}>
            <Typography>Search</Typography>
            <Box pl={1}>
              <CircularProgress color="inherit" size={18} />
            </Box>
          </Stack>
        ) : (
          'Search'
        )}
      </Button>
    </Stack>
  )
}
