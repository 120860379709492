import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppSelector } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { Box, Grid, Typography } from '@mui/material'
import { AmazonChart as AmazonChartData } from 'pages/Company/Chart/Amazon/Model'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import { renderApplogieDate } from 'components/Utility'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
export default function AmazonChart() {
  const amazonDataById = useAppSelector((state) => state.amazonDataRes)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let data: AmazonChartData[] = []

  const [value, setValue] = useState(false)

  useEffect(() => {
    let amazonChartData = [] as any[]
    if ('getAmazonDataRes' in amazonDataById) {
      if (amazonDataById.getAmazonDataRes.results !== undefined) {
        amazonChartData = amazonDataById.getAmazonDataRes.results.map((item) => {
          return {
            date: renderApplogieDate(item.data_date),
            total: Number(item.total),
            ec2: Number(item.ec2),
            s3: Number(item.s3),
            dataTransfer: Number(item.data_transfer),
            elasticCalculatePhase: Number(item.elastic_cache),
            cloudFront: Number(item.cloud_front),
            efs: Number(item.efs),
            route53: Number(item.route_53),
            clousWatch: Number(item.cloud_watch),
            rds: Number(item.rds),
            awsSupport: Number(item.support),
            devSupport: Number(item.dev_support),
            lambda: Number(item.aws_lambda),
            mq: Number(item.mq),
            vpc: Number(item.vpc),
          }
        })
      }
    }
    amazonChartData.reverse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data = amazonChartData
    if (data.length > 0) {
      setValue(true)
    }

    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new('amazonDataChart')
    var modal = am5.Modal.new(root, {
      content: 'Amazon chart has no data',
    })
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
        pinchZoomX: true,
      }),
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      }),
    )
    cursor.lineY.set('visible', false)

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'date',
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    )

    xAxis.data.setAll(data)

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#,###",
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )
    // Add label to y-axis
    yAxis.children.unshift(
      am5.Label.new(root, {
        text: 'Service Cost',
        textAlign: 'center',
        y: am5.p50,
        rotation: -90,
        fontSize: 13,
        fontWeight: '600',
      }),
    ) 
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const createSeries = (name: any, field: any) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: false,
          valueYField: field,
          categoryXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            // eslint-disable-next-line no-template-curly-in-string
            labelText: '[bold]{name}[/]: ${valueY}',
          }),
          legendValueText: `[bold]$ {valueY}`,
        }),
      )

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      })

      series.data.setAll(data)
      series.appear(1000)
    }
    createSeries('Total', 'total')
    createSeries('EC2', 'ec2')
    createSeries('S3', 's3')
    createSeries('Data Transfer', 'dataTransfer')
    createSeries('Elastic Calculate Phase', 'elasticCalculatePhase')
    createSeries('Cloud Front', 'cloudFront')
    createSeries('EFS', 'efs')
    createSeries('route53', 'route53')
    createSeries('Clous Watch', 'clousWatch')
    createSeries('RDS', 'rds')
    createSeries('AWS Support', 'awsSupport')
    createSeries('Dev Support', 'devSupport')
    createSeries('Lambda', 'lambda')
    createSeries('MQ', 'mq')
    createSeries('VPC', 'vpc')

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    )

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      }),
    )
    if (data.length === 0) {
      modal.open()
    } else {
      modal.close()
    }
    legend.data.setAll(chart.series.values)

    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'AmazonChart',
      dataSource: data,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100)
    return () => root.dispose()
  }, [data, amazonDataById])

  return (
    <Box sx={{ height: '80vh' }}>
      {value ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Amazon Usage</Typography>
          </Grid>
        </Grid>
      ) : null}
      <Box id="amazonDataChart" style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}
