import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { Result as Department } from 'reduxStore/subcription/Department/Model'
import { Result } from 'reduxStore/subcription/Active/Model'
import { Result as Status } from 'reduxStore/subcription/Status/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  EditSubscriptionBody,
  MainBody,
} from 'reduxStore/subcription/editSubscription/editSubscriptionModel'
import {
  editSubscriptionApi,
  editSubscriptionClearRes,
} from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import { checkReturnValue } from 'components/Utility'

type Props = {
  item?: Result | any
}

export default function EditSubscription({ item }: Props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [width, setWidth] = useState('')
  useEffect(() => {
    setWidth(fullScreen ? '100%' : '30vw')
  }, [fullScreen])
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const subsValue = useAppSelector((state) => state.subscription)
  const subsStatusValue = useAppSelector((state) => state.subscriptionstatus)
  const companyUserList = useAppSelector((state) => state.companyUserList)
  const subsDepartmentValue = useAppSelector((state) => state.subsdepartment)
  useEffect(() => {
    if ('results' in subsValue.subscriptions) {
      if (subsValue.subscriptions.results.length > 0) {
        // setSubscriptionExpanded([...Array(subsValue.subscriptions.results.length)].map(() => false))
      }
    }
    if ('status' in subsStatusValue) {
      if ('results' in subsStatusValue.status) {
        setStatusValue(subsStatusValue.status.results)
      }
    }
    if ('department' in subsDepartmentValue) {
      if ('results' in subsDepartmentValue.department) {
        setDepartmentValue(subsDepartmentValue.department.results)
      }
    }

    if ('companyUserList' in companyUserList) {
      if ('results' in companyUserList.companyUserList) {
        if (
          companyUserList.companyUserList.results !== undefined ||
          companyUserList.companyUserList.results !== null
        ) {
          if (companyUserList.companyUserList.results.length > 0) {
            for (var i = 0; i < companyUserList.companyUserList.results.length; i++) {
              setOwnerValue(companyUserList.companyUserList.results)
            }
          }
        }
      }
    }

    if (editSubscriptionValue.editresponse.id !== undefined) {
      // setEnableBackdrop(true)
    } else {
      if (editSubscriptionValue.error === 'Something went wrong') {
        // setEnableBackdrop(true)
      } else {
        // setEnableBackdrop(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsValue])

  const [ownerValue, setOwnerValue] = React.useState([] as any[])
  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null)
  const setNewDateValue = (newValue: Dayjs | null) => {
    setDateValue(dayjs(newValue))
  }
  const [selectedOwner, setSelectedOwner] = React.useState(null)
  const filterOwner = (owner: any, _e: any) => {
    if (owner !== null && owner.id !== null) {
      setSelectedOwner(owner.id)
    }
  }
  const [notes, setNotes] = useState('')
  const [aliasName, setAliasName] = useState('')
  useEffect(() => {
    setNotes(item?.description !== undefined ? item.description : '')
    setAliasName(item.aliasName !== '---' ? item.aliasName : '')
    setDateValue(
      checkReturnValue(item?.renewalDate) !== 'Not Set'
        ? checkReturnValue(dayjs(item?.renewalDate))
        : null,
    )
  }, [item])
  const [ignoreChecked, setIgnoreChecked] = React.useState(false)
  const handleChangeIgnore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIgnoreChecked(event.target.checked)
  }
  const [departmentValue, setDepartmentValue] = React.useState([] as Department[])
  const [selectedDepartment, setSelectedDepartment] = React.useState(null)
  const filterDepartment = (department: any, _e: any) => {
    if (department !== null && department.id !== null) {
      setSelectedDepartment(department.id)
    }
  }
  const [monthlyCost, setMonthlyCost] = React.useState('')
  const [statusValue, setStatusValue] = React.useState([] as Status[])
  const [selectedStatus, setSelectedStatus] = React.useState(null)
  const filterStatus = (status: any, _e: any) => {
    if (status !== null && status.id !== null) {
      setSelectedStatus(status.id)
    }
  }
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)

  const editSubscriptions = async (row: any) => {
    let editApiCallBody = {} as EditSubscriptionBody
    editApiCallBody = {
      description: notes,
      monthly_projected_cost:
        monthlyCost !== ''
          ? monthlyCost.replace(',', '').replace('$', '')
          : row.monthly_projected_cost.replace(',', '').replace('$', ''),
    } as EditSubscriptionBody
    if (dateValue !== null) {
      Object.assign(editApiCallBody, { renewal_date: dayjs(dateValue).format('YYYY-MM-DD') })
    }
    if (ignoreChecked !== false) {
      Object.assign(editApiCallBody, { ignore: ignoreChecked })
    }
    if (selectedOwner !== null) {
      Object.assign(editApiCallBody, { owner_id: selectedOwner })
    }
    if (selectedDepartment !== null) {
      Object.assign(editApiCallBody, { department_id: selectedDepartment })
    }
    if (selectedStatus !== null) {
      Object.assign(editApiCallBody, { status_id: selectedStatus })
    }
    if (aliasName !== null || aliasName !== '') {
      Object.assign(editApiCallBody, { vendor_alias: aliasName })
    }
    // Edit Subscription Main Body
    let mainBody = {
      id: row.accountId,
      token: Token!,
      body: editApiCallBody,
    } as MainBody
    await dispatch(editSubscriptionApi(mainBody))
    await dispatch(editSubscriptionClearRes())
    await dispatch(fetchSubscription(Token!))
    await setDateValue(null)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ minWidth: width, maxWidth: width }}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" gutterBottom component="div">
              Edit Subscription
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ minWidth: width, maxWidth: width }}
          >
            <Divider style={{ color: '#000000', width: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ minWidth: width, maxWidth: width }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionName-id"
              label="Subscription Name"
              variant="standard"
              disabled
              value={item.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="Alias Name"
              variant="standard"
              fullWidth
              value={aliasName}
              onChange={(e) => {
                setAliasName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAccNum-id"
              label="Account Number"
              variant="standard"
              disabled
              value={item.account}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Autocomplete
              id="owner-states"
              value={
                ownerValue !== undefined
                  ? ownerValue.find((element: any) => element.id === selectedOwner) || item.owner
                  : null
              }
              options={ownerValue}
              onChange={(event: any, newValue: any) => {
                filterOwner(newValue, event)
              }}
              getOptionLabel={(option: any) => option.user.username}
              isOptionEqualToValue={(option: any, value) => option.user.id === value.user.id}
              renderInput={(params) => (
                <TextField {...params} label="Owner" variant="standard" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Renewal Date"
                value={dateValue}
                onChange={(value: any) => {
                  setNewDateValue(value)
                }}
                slotProps={{ textField: { variant: 'standard' } }}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionMonthProCost-id"
              label="Monthly Projected Cost"
              variant="standard"
              fullWidth
              onChange={(e) => {
                setMonthlyCost(e.target.value)
              }}
              defaultValue={
                item?.monthly_projected_cost !== undefined
                  ? `${
                      item.monthly_projected_cost !== null
                        ? numberWithDecimalCommas(Number(item.monthly_projected_cost))
                        : '$0.00'
                    }`
                  : 'not-set'
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Autocomplete
              value={
                departmentValue !== undefined
                  ? departmentValue.find((element: any) => element.id === selectedDepartment) ||
                    item.departmentList
                  : null
              }
              id="dept-states"
              options={departmentValue}
              onChange={(event: any, newValue: any) => {
                filterDepartment(newValue, event)
              }}
              renderInput={(params) => (
                <TextField {...params} label="Department Name" variant="standard" fullWidth />
              )}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option: any, value) => option.name === value.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Autocomplete
              id="status-states"
              value={
                statusValue !== undefined
                  ? statusValue.find((element: any) => element.id === selectedStatus) ||
                    item.statustList
                  : null
              }
              options={statusValue}
              onChange={(event: any, newValue: any) => {
                filterStatus(newValue, event)
              }}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option: any, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Status" variant="standard" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="standardNote-id"
              label="Notes"
              variant="standard"
              fullWidth
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              control={<Checkbox checked={ignoreChecked} onChange={handleChangeIgnore} />}
              label="Ignore This Account"
              sx={{ fontStyle: 'bold' }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Button
                  onClick={() => editSubscriptions(item)}
                  variant="contained"
                  fullWidth={fullScreen ? true : false}
                >
                  {editSubscriptionValue.loading === true ? (
                    <Stack direction="row" spacing={1}>
                      <Typography>Save</Typography>
                      <Box pl={1}>
                        <CircularProgress color="inherit" size={18} />
                      </Box>
                    </Stack>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
