import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/account/accountPaymentList/AccountPaymentListByIdModel'
import UpdatePayment from './UpdatePayment'
import AddIcon from '@mui/icons-material/Add'
import DeletePayment from './DeletePayment'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { checkReturnValue } from 'components/Utility'
import { SubscriptionPaymentListTableColumn } from './PaymentList/SubscriptionPaymentListTableColumn'
import {
  DataGridPremium,
} from '@mui/x-data-grid-premium'
import { useParams } from 'react-router-dom'
import { CompanyAccountDetailsModelReq } from 'reduxStore/account/companyAccountDetailsById/companyAccountDetailsModel'
import { fetchAccountPaymentListById } from 'reduxStore/account/accountPaymentList/accountPaymentListByIdSlice'
import { updateDeletePaymentClearRes } from 'reduxStore/account/accountPaymentDeleteByID/deletePaymentByIDSlice'
import { ModifiedPaymentList } from './Model'
import { updatePaymentClearRes } from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'
import TransferWrongPayment from './PaymentList/TransferWrongPayment'
import { detectWrongPaymentDialogState, wrongPaymentClearMsg, wrongPaymentClearRes } from 'reduxStore/account/detectWrongPayment/detectWrongPaymentSlice'

export default function PaymentList() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  // Initiate Payment List API on Page Render
  useEffect(() => {
    dispatch(
      fetchAccountPaymentListById({
        accountId: String(id),
        token: Token,
      } as CompanyAccountDetailsModelReq),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [paymentList, setPaymentList] = useState([] as ModifiedPaymentList[])
  // Call Payment List Store
  const accountDetailsById = useAppSelector((state) => state.accountPaymentListById)
  // Modify dataset for Payment List table
  useEffect(() => {
    setPaymentList(
      checkReturnValue(accountDetailsById?.accountPaymentList?.results) !== 'Not Set'
        ? accountDetailsById?.accountPaymentList?.results.map((item: Result) => ({
          id: item.id,
          date:
          checkReturnValue(item.data_date) !== 'Not Set'
            ? (checkReturnValue(item.data_date))
            : null,  
          amount: checkReturnValue(item?.cost) !== 'Not Set' ? item?.cost : null,
          memo: checkReturnValue(item?.memo) !== 'Not Set' ? item?.memo : null,
        }))
        : ([] as ModifiedPaymentList[]),
    )
  }, [accountDetailsById])

  let getIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')

  const [selectedItem, setSelectedItem] = useState({} as ModifiedPaymentList)
  const updateSelectedItemValFromChild = (passedVal: ModifiedPaymentList) => {
    setSelectedItem(passedVal)
  }
  const [showUpdatePaymentDialog, setShowUpdatePaymentDialog] = useState(false)
  const updateEditPaymentDialogValFromChild = (passedVal: boolean) => {
    if (passedVal) {
    }
    setShowUpdatePaymentDialog(passedVal)
  }

  const [selectedPaymentItem, setSelectedPaymentItem] = useState('')
  const [updateSubscriptionPaymentAPIMsgType, setUpdateSubscriptionPaymentAPIMsgType] =
    useState<AlertColor>('success')
  const [updateSubscriptionPaymentMsg, setUpdateSubscriptionPaymentMsg] = useState('')
  const closeUpdatePaymentSnackbar = () => {
    dispatch(updatePaymentClearRes())
    setUpdateSubscriptionPaymentMsg('')
  }

  const [transferSubscriptionPaymentAPIMsgType, setTransferSubscriptionPaymentAPIMsgType] =
    useState<AlertColor>('success')
  const [transferSubscriptionPaymentMsg, setTransferSubscriptionPaymentMsg] = useState('')
  const closeTransferPaymentSnackbar = () => {
    dispatch(wrongPaymentClearRes())
    setTransferSubscriptionPaymentMsg('')
  }

  // Call Update payment store
  const updatePaymentRes = useAppSelector((state) => state.updatePaymentRes)
  const transferPaymentRes = useAppSelector((state) => state.DetectWrongPayment)

  // Do the following action when Update Payment store value has been changed
  useEffect(() => {
    setSelectedItem(updatePaymentRes?.selectedItem)
    setShowUpdatePaymentDialog(updatePaymentRes?.enableDialog)

    if (updatePaymentRes?.succssMessage !== '') {
      setUpdateSubscriptionPaymentAPIMsgType('success')
      setUpdateSubscriptionPaymentMsg(updatePaymentRes?.succssMessage)
    }
    if (updatePaymentRes?.error !== '') {
      if (updatePaymentRes?.error) {
        setUpdateSubscriptionPaymentAPIMsgType('error')
        setUpdateSubscriptionPaymentMsg(updatePaymentRes?.error)
      }
    }
  }, [updatePaymentRes])

  useEffect(() => {
    if (transferPaymentRes?.succssMessage !== '') {
      setTransferSubscriptionPaymentAPIMsgType('success')
      setTransferSubscriptionPaymentMsg(transferPaymentRes?.succssMessage)
      dispatch(
        fetchAccountPaymentListById({
          accountId: String(id),
          token: Token,
        } as CompanyAccountDetailsModelReq),
      )
      dispatch(detectWrongPaymentDialogState(false))
      dispatch(wrongPaymentClearMsg())
    }
    if (transferPaymentRes?.error !== '') {
      if (transferPaymentRes?.error) {
        setTransferSubscriptionPaymentAPIMsgType('error')
        setTransferSubscriptionPaymentMsg(transferPaymentRes?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferPaymentRes])

  useEffect(() => {
    setSelectedTransferItem(transferPaymentRes?.selectedItem)
    setShowTransferPaymentDialog(transferPaymentRes?.enableDialog)
  }, [transferPaymentRes])

  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = useState(false)
  const updateDeletePaymentDialogValFromChild = (passedVal: boolean) => {
    setShowDeletePaymentDialog(passedVal)
  }

  const [paymentDelAPIMsgType, setPaymentDelAPIMsgType] = useState<AlertColor>('success')
  const [paymentDelSuccessMsg, setPaymentDelSuccessMsg] = useState('')
  const deletePaymentMessage = (value: boolean) => {
    if (value) {
      dispatch(updateDeletePaymentClearRes())
      setPaymentDelSuccessMsg('')
    }
  }

  const [selectedTranferItem, setSelectedTransferItem] = useState({} as ModifiedPaymentList)
  const transferSelectedItemValFromChild = (passedVal: ModifiedPaymentList) => {
    setSelectedTransferItem(passedVal)
  }
  const [showTransferPaymentDialog, setShowTransferPaymentDialog] = useState(false)
  const tansferPaymentDialogValFromChild = (passedVal: boolean) => {
    setShowTransferPaymentDialog(passedVal)

  }

  // Call delete payment store
  const deletePaymentByIDRes = useAppSelector((state) => state.deletePaymentByID)
  // Do the following action when delete Payment store value has been changed
  useEffect(() => {
    setShowDeletePaymentDialog(deletePaymentByIDRes?.enableDeletePaymentDialog)
    setSelectedPaymentItem(deletePaymentByIDRes?.selectedItem)
    if (deletePaymentByIDRes?.response?.message !== undefined) {
      if (deletePaymentByIDRes?.response?.message !== '') {
        setPaymentDelSuccessMsg(deletePaymentByIDRes?.response?.message)
        setPaymentDelAPIMsgType('success')
      }
    }
    if (deletePaymentByIDRes?.error !== '') {
      setPaymentDelSuccessMsg(deletePaymentByIDRes?.error)
      setPaymentDelAPIMsgType('success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentByIDRes])

  return (
    <>
      <Card sx={{ minHeight: '330px' }}>
        <CardHeader
          avatar={<CreditCardIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Payments
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        checkReturnValue(accountDetailsById?.accountPaymentList?.results) !==
                          'Not Set'
                          ? accountDetailsById.accountPaymentList.results.length
                          : 0
                      }
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        dispatch(
                          fetchAccountPaymentListById({
                            accountId: String(id),
                            token: Token,
                          } as CompanyAccountDetailsModelReq),
                        )
                      }
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {getIndividualUsageUpdate !== null ? (
                getIndividualUsageUpdate === 'true' ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setShowUpdatePaymentDialog(true)
                      }}
                    >
                      Add Payment
                    </Button>
                  </Grid>
                ) : null
              ) : null}
            </Grid>
          }
        />
        <CardContent>
          <Box sx={{ height: '30vh', width: '100%', overflow: 'auto' }}>
            <DataGridPremium
              columns={SubscriptionPaymentListTableColumn()}
              rows={paymentList}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              hideFooter
              disableRowGrouping
              disableAggregation
              disableRowSelectionOnClick
              loading={accountDetailsById.loading || deletePaymentByIDRes?.loading}
              localeText={{ noRowsLabel: 'No Payment Details available' }}
            />
          </Box>
        </CardContent>
      </Card>
      {showUpdatePaymentDialog ? (
        <UpdatePayment
          selectedItem={selectedItem}
          updateSelectedItem={updateSelectedItemValFromChild}
          updatePaymentDialogEnable={showUpdatePaymentDialog}
          updatePaymentDialogUpdateState={updateEditPaymentDialogValFromChild}
        />
      ) : null}
      {showTransferPaymentDialog ? (
        <TransferWrongPayment
          selectedPaymentTransItem={selectedTranferItem}
          transferSelectedItem={transferSelectedItemValFromChild}
          transferWrongPaymentDialogEnable={showTransferPaymentDialog}
          transferWrongPaymentDialogUpdateState={tansferPaymentDialogValFromChild}
        />
      ) : null}
      {showDeletePaymentDialog ? (
        <DeletePayment
          itemId={selectedPaymentItem}
          deletePaymentDialogEnable={showDeletePaymentDialog}
          deletePaymentDialogUpdateState={updateDeletePaymentDialogValFromChild}
        />
      ) : null}
      {updateSubscriptionPaymentMsg !== '' && (
        <ApplogieSnackbar
          msg={updateSubscriptionPaymentMsg}
          type={updateSubscriptionPaymentAPIMsgType}
          alertClose={closeUpdatePaymentSnackbar}
        />
      )}
      {paymentDelSuccessMsg !== '' && (
        <ApplogieSnackbar
          msg={paymentDelSuccessMsg}
          type={paymentDelAPIMsgType}
          alertClose={deletePaymentMessage}
        />
      )}
      {transferSubscriptionPaymentMsg !== '' && (
        <ApplogieSnackbar
          msg={transferSubscriptionPaymentMsg}
          type={transferSubscriptionPaymentAPIMsgType}
          alertClose={closeTransferPaymentSnackbar}
        />
      )}
    </>
  )
}
