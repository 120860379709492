import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { AddUserRes, AddUserBody } from 'reduxStore/customer/companyUser/addCompanyUser/Model'

type InitialState = {
  loading: boolean
  addCompanyUserRes: AddUserRes
  successMessage: string
  errorMessage: string
}
const initialState: InitialState = {
  loading: false,
  addCompanyUserRes: {} as AddUserRes,
  successMessage: '',
  errorMessage: '',
}

// Generates pending, fulfilled and rejected action types
export const addCompanyUser = createAsyncThunk<AddUserRes, AddUserBody>(
  'addCompanyUser/addCompanyUser',
  (payload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/users`, payload.body, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error.response.data !== undefined
            ? error.response.data.message !== undefined
              ? error.response.data.message
              : error.response.data[0].detail !== undefined
              ? error.response.data[0].detail
              : 'Something went wrong'
            : 'Something went wrong',
        ),
      )
  },
)

const addCompanyUserSlice = createSlice({
  name: 'addCompanyUser',
  initialState,
  reducers: {
    addUserClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    addUserClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.addCompanyUserRes = {} as AddUserRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCompanyUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addCompanyUser.fulfilled, (state, action: PayloadAction<AddUserRes>) => {
      state.loading = false
      state.addCompanyUserRes = action.payload
      state.successMessage = 'User has been added successfully'
      state.errorMessage = ''
    })
    builder.addCase(addCompanyUser.rejected, (state, action) => {
      state.loading = false
      state.addCompanyUserRes = {} as AddUserRes
      state.successMessage = ''
      state.errorMessage = String(action.payload!)
    })
  },
})
// Action creators are generated for each case reducer function
export const { addUserClearMsg, addUserClearRes } = addCompanyUserSlice.actions

export default addCompanyUserSlice.reducer
