import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle, Stack, Typography, IconButton, Collapse, Divider } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CloseIcon from '@mui/icons-material/Close'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Results } from 'reduxStore/alert/Active/Model'
import { useNavigate } from 'react-router-dom'
import { renderApplogieDate } from './Utility';

type Props = {
  item?: Results | any
  selectedItem: (newType: number) => void
}

const Accordions = ({ item, selectedItem }: Props) => {
  let navigate = useNavigate()

  const [isActive, setIsActive] = useState(false)
  const [data, setData] = useState({} as Results)

  const showId = (id: number) => {
    navigate(`/detail/${id}`)
  }

  useEffect(() => {
    setData(item)
  }, [item, selectedItem])

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <Alert icon={false} variant="filled" severity="info" key={data.id} color="error">
          <AlertTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <ErrorOutlineIcon sx={{ paddingTop: '2px', paddingRight: '4px' }} />
                <Typography
                  variant="subtitle1"
                  onClick={() => showId(data.account.id)}
                  sx={{
                    textTransform: 'none',
                    '&:hover': { cursor: 'pointer', textTransform: 'none' },
                  }}
                >
                  {data.message}
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <IconButton aria-label="delete">
                  {isActive ? (
                    <ExpandMoreOutlinedIcon sx={{ color: '#ffffff' }} />
                  ) : (
                    <ChevronRightOutlinedIcon sx={{ color: '#ffffff' }} />
                  )}
                </IconButton>
                <IconButton aria-label="delete" onClick={() => selectedItem(data!.id!)}>
                  <CloseIcon sx={{ color: '#ffffff' }}  />
                </IconButton>
              </Stack>
            </Stack>
          </AlertTitle>
          <Collapse in={isActive}>
            <Divider sx={{ backgroundColor: '#ffffff' }} />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">Account</Typography>
              <Typography>
                {data.account !== undefined
                  ? data.account !== null
                    ? data.account!.vendor!.name
                    : null
                  : null}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
              <Typography variant="subtitle1">Visible</Typography>
              <Typography>{renderApplogieDate(data.from_date!)}</Typography>
              <Typography>{renderApplogieDate(data.to_date)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
              <Typography variant="subtitle1">Type</Typography>
              <Typography></Typography>
            </Stack>
          </Collapse>
        </Alert>
      </div>
    </div>
  )
}

export default Accordions
