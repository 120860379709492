import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Link,
  Box,
  CircularProgress,
  Stack,
  AlertColor,
  TextField,
  Alert,
  CardMedia,
  Checkbox,
  FormControlLabel,
  CardHeader,
  Divider,
  Icon,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginBody } from 'reduxStore/login/Model'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'reduxStore/store'
import { login, updateLoginClearRes } from 'reduxStore/login/loginSlice'
import { useAppSelector } from 'reduxStore/hooks'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { useForm } from 'react-hook-form'
import FeatureImageLogin from '../images/applogie_logo_white.svg'
import ArrowImageIcon from '../images/ArrowBackFilled.svg'
declare module '@mui/material' {
  interface DefaultTheme extends Theme { }
}

export default function SignIn() {
  const dispatch = useDispatch<AppDispatch>()
  let navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem('token')
  const theme = createTheme({
    palette: {
      primary: {
        main: '#008F70',
      },
      secondary: {
        main: '#330101',
      },
      background: {
        default: "#091231",
      },
      text: {
        primary: '#FFFFF',
      }
    },
  })

  const [loginMsgType, setLoginMsgType] = React.useState<AlertColor>('success')
  const closeLoginSnackbar = () => {
    dispatch(updateLoginClearRes())
    setLoginMsg('')
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const [showPassword, setShowPassword] = React.useState(false)
  //handle button click of login form
  const onSubmit = (data: any) => {
    const loginBody = {
      email_or_username: data.email,
      password: data.password,
    } as LoginBody
    dispatch(login(loginBody))
  }

  const [loginMsg, setLoginMsg] = React.useState('')

  // navigate to dashboard page on login success
  if (token) {
    setTimeout(() => {
      navigate('/dashboard')
      dispatch(updateLoginClearRes())
      setLoginMsg('')
    }, 100)
  }

  // Call Login store
  const loginDetails = useAppSelector((state) => state.login)
  React.useEffect(() => {
    if (loginDetails?.successMsg !== '') {
      setLoginMsgType('success')
      setLoginMsg(loginDetails?.successMsg)
    }
    if (loginDetails?.error !== '') {
      setLoginMsg(loginDetails?.error)
      setLoginMsgType('error')
    }
  }, [loginDetails])
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        columns={{ xs: 4, sm: 8, md: 12 }}
        p={1}
        sx={{ minHeight: '90vh' }}
      >
        <Grid item xs={4} sm={12} md={4}>
          <Grid item sx={{ pb: 2.5 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Icon component="img" src={ArrowImageIcon} style={{ width: 24, height: 24, paddingLeft: 1 }} />
              </Grid>
              <Grid item>
                <Link
                  underline="none"
                  target="_blank"
                  href="https://applogie.com"
                  style={{ cursor: 'pointer', color: '#FFFFFF' }}
                >
                  <Typography variant="body2" style={{ fontSize: "16px", paddingTop: "2px" }}>{'Return to Applogie.com'}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Card sx={{ display: 'flex', width: "auto", borderRadius: "0.3em", }}>
            <Box sx={{ backgroundColor: "#008F70", width: "426px", minHeight: "383px" }}>
              <CardMedia
                component="img"
                sx={{ width: "243px", height: "53px", display: "block", margin: 'auto', marginTop: "11em" }}
                image={FeatureImageLogin}
                alt="ApplogieLogo"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: "426px", minHeight: "383px" }}>
              <CardHeader title="Log In"
                titleTypographyProps={{
                  variant: 'h5',
                  fontSize: '24px',
                  fontFamily: 'Noto Sans',
                  fontWeight: 400,
                }}
              />
              <Divider />
              <CardContent sx={{
                width: '426px',
                minHeight: '318px',
                padding: '16px',
              }}>
                <CssBaseline />
                {location.search.includes('?org=resetpwd') && (
                  <Alert severity="info">Please login with your new credentials</Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <TextField
                    label="Email address"
                    placeholder="Enter your Email address"
                    variant="standard"
                    fullWidth
                    {...register('email', {
                      required: 'Invalid log in credentials',
                      setValueAs: (value) =>
                        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(value.toLowerCase())
                          ? value.toLowerCase()
                          : value,
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors!.email?.message!}
                    sx={{ pb: 2 }}
                  />
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="standard"
                    fullWidth
                    placeholder="Enter your password"
                    autoComplete="new-password"
                    {...register('password', {
                      required: 'Invalid log in credentials',
                      minLength: {
                        value: 8,
                        message: 'Password must have at least 8 characters',
                      },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                    <Grid item sx={{ p: 1.5 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="checkbox"
                            color="primary"
                          />
                        }
                        label="Keep my logged in"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ my: 1, backgroundColor: '#30A289' }}
                      >
                        {loginDetails.loading === true ? (
                          <Stack direction="row" spacing={1}>
                            <Typography> log In</Typography>
                            <Box>
                              <CircularProgress color="inherit" size={18} />
                            </Box>
                          </Stack>
                        ) : (
                          'Log In'
                        )}
                      </Button>
                    </Grid>
                    <Grid item sx={{ pt: 1 }}>
                      <Button variant="text" fullWidth
                        size="large"
                        onClick={() => navigate('/forgot-password')}
                      >FORGOT YOUR PASSWORD?</Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <ApplogieSnackbar msg={loginMsg} type={loginMsgType} alertClose={closeLoginSnackbar} />
    </ThemeProvider>
  )
}
