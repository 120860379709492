import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { ClearAllAlertsReq, ClearAllAlerts } from 'reduxStore/alert/ClearAllAlerts/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  clearAllAlerts: ClearAllAlerts
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  clearAllAlerts: {} as ClearAllAlerts,
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const clearAllAlerts = createAsyncThunk<ClearAllAlerts, ClearAllAlertsReq>(
  'alert/clearAlerts',
  (reqParam: ClearAllAlertsReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/alert/clear_all_alerts`, null, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const clearAllAlertsSlice = createSlice({
  name: 'clearAlert',
  initialState,
  reducers: {
    updateClearAllAlertClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateClearAllAlertClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.clearAllAlerts = {} as ClearAllAlerts
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAllAlerts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(clearAllAlerts.fulfilled, (state, action: PayloadAction<ClearAllAlerts>) => {
      state.loading = false
      state.clearAllAlerts = action.payload
      state.successMsg =
        action?.payload?.status !== undefined
          ? action?.payload?.status !== ''
            ? 'alert cleared'
            : 'alert cleared'
          : ''
      state.error = ''
    })
    builder.addCase(clearAllAlerts.rejected, (state, action) => {
      state.loading = false
      state.clearAllAlerts = {} as ClearAllAlerts
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})

export const { updateClearAllAlertClearMsg, updateClearAllAlertClearRes } = clearAllAlertsSlice.actions
export default clearAllAlertsSlice.reducer
