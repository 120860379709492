import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { WrongPaymentReq, WrongPaymentRes } from './detectWrongPaymentModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  wrongPaymentList: WrongPaymentRes
  succssMessage: string
  error: string
  selectedItem: any
  enableDialog: boolean
}
const initialState: InitialState = {
  loading: false,
  wrongPaymentList: {} as WrongPaymentRes,
  succssMessage: '',
  error: '',
  selectedItem: {} as any,
  enableDialog: false,
}

// Generates pending, fulfilled and rejected action types
export const detectWrongPayment = createAsyncThunk<WrongPaymentRes, WrongPaymentReq>(
  'detectWrongPayments/detectWrongPayments',
  (reqParam: WrongPaymentReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/payment/change_payment_vendor`, reqParam?.updateWrongBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const wrongPaymentSlice = createSlice({
  name: 'wrongPaymentDetails',
  initialState,
  reducers: {
    wrongPaymentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    wrongPaymentClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.wrongPaymentList = {} as WrongPaymentRes
    },
    detectWrongPaymentDetailsID: (state, action) => {
      state.selectedItem = action.payload
    },
    detectWrongPaymentDialogState: (state, action) => {
      state.enableDialog = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(detectWrongPayment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(detectWrongPayment.fulfilled, (state, action: PayloadAction<WrongPaymentRes>) => {
      state.loading = false
      state.wrongPaymentList = action.payload
      state.succssMessage =
        action?.payload?.message !== undefined
          ? action?.payload?.message !== ''
            ? action?.payload?.message
            : 'Successfully detected wrong payment'
          : 'Successfully detected wrong payment'
      state.error = ''
    })
    builder.addCase(detectWrongPayment.rejected, (state, action) => {
      state.loading = false
      state.wrongPaymentList = {} as WrongPaymentRes
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
// Action creators are generated for each case reducer function
export const {
  wrongPaymentClearMsg,
  wrongPaymentClearRes,
  detectWrongPaymentDetailsID,
  detectWrongPaymentDialogState
} = wrongPaymentSlice.actions
export default wrongPaymentSlice.reducer
