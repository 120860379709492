import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { RenewalsListReq, VendorLicenseRenewalRes } from 'reduxStore/renewals/model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  response: VendorLicenseRenewalRes
  error: string
  successMessage: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as VendorLicenseRenewalRes,
  successMessage: '',
  error: '',
}

export const fetchVendorLicenseRenewals = createAsyncThunk<
  VendorLicenseRenewalRes,
  RenewalsListReq
>(
  'vendorLicenseRenewals/vendorLicenseRenewals',
  async (payload: RenewalsListReq, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/customers/renewals/`, { headers })
      .then((response) => response.data)
      .catch(
        (error) =>
          rejectWithValue(error.response.data[0].detail) ||
          error.response.data.message ||
          'Something went wrong',
      )
  },
)

const vendorLicenseRenewalsSlice = createSlice({
  name: 'vendorLicenseRenewals',
  initialState,
  reducers: {
    updateVendorLicenseRenewalsClearMsg: (state, _action) => {
      state.successMessage = ''
      state.error = ''
    },
    updateVendorLicenseRenewalsClearRes: (state, _action) => {
      state.successMessage = ''
      state.error = ''
      state.response = {} as VendorLicenseRenewalRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendorLicenseRenewals.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchVendorLicenseRenewals.fulfilled,
      (state, action: PayloadAction<VendorLicenseRenewalRes>) => {
        state.loading = false
        state.response = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchVendorLicenseRenewals.rejected, (state, action) => {
      state.loading = false
      state.response = {} as VendorLicenseRenewalRes
      state.error = String(action.payload!)
    })
  },
})

export const { updateVendorLicenseRenewalsClearMsg, updateVendorLicenseRenewalsClearRes } =
  vendorLicenseRenewalsSlice.actions
export default vendorLicenseRenewalsSlice.reducer
