import { Box, Link, Switch } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import { useAppDispatch } from 'reduxStore/hooks'
import { updateUserStatus } from 'reduxStore/account/inactiveUser/inactiveUserSlice'
import { renderApplogieDate } from 'components/Utility'

export function UserListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
          {params.row.firstName !== "" ? 
          <Link href={`/userdetail/${params.row.userId}`} variant='subtitle2' underline="none"> 
          <b>{params.row.firstName}</b></Link>
           : null}
           </Box>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
          {params.row.firstName !== "" ? 
          <Link href={`/userdetail/${params.row.userId}`} variant='subtitle2' underline="none"> 
          <b>{params.row.lastName}</b></Link>
           : null}
           </Box>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
          {params.row.firstName !== "" ? 
          <Link href={`/userdetail/${params.row.userId}`} variant='subtitle2' underline="none"> 
          <b>{params.row.email}</b></Link>
           : null}
           </Box>
        )
      },
    },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Switch
            checked={
              params.row.isActive !== null || params.row.isActive !== undefined
                ? params.row.isActive
                : false
            }
            onClick={() => {
              dispatch(updateUserStatus(params.row.id))
            }}
          />
        )
      },
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      sortable: true,
      hideable: true,
      minWidth: 128,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {params.row.lastLogin !== null
            ? renderApplogieDate(params.row.lastLogin!)
            : "01/01/1970, 17:30"}
        </Box>
      ),
    },
  ]
  return columns
}
