import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoutes } from 'Authentication/PrivateRoutes'
import Login from './pages/Signin'
import Dashboard from './pages/Dashboard/Index'
import ForgotPassword from './pages/ForgotPassword'
import { Breaches } from './pages/Breaches/Breaches'
import { BreachesDetails } from 'pages/Breaches/BreachesDetails'
import { SubscriptionLayout } from './pages/Subscription/Index'
import { UsersLayout } from './pages/users/UsersLayout'
import { Optimizations } from './pages/Optimizations/Index'
import CompanyDetails from 'pages/Company/CompanyDetails'
import AccountDetails from 'pages/Account/AccountDetails'
import UserDetails from 'pages/users/UserDetails/UserDetails'

function Router() {
  const token = localStorage.getItem('token')
  return (
    <div>
      <Routes>
        <Route path="/" element={token ? <PrivateRoutes component={Dashboard} /> : <Login />} />
        <Route
          path="/login"
          element={token ? <PrivateRoutes component={Dashboard} /> : <Login />}
        />
        <Route path="/dashboard" element={<PrivateRoutes component={Dashboard} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/subscriptions" element={<PrivateRoutes component={SubscriptionLayout} />} />
        <Route path="/users/:tagName?" element={<PrivateRoutes component={UsersLayout} />} />
        <Route path="/optimizations" element={<PrivateRoutes component={Optimizations} />} />
        <Route path="/breaches" element={<PrivateRoutes component={Breaches} />} />
        <Route path="/breachdetail/:id" element={<PrivateRoutes component={BreachesDetails} />} />
        <Route path="/detail/:id" element={<PrivateRoutes component={CompanyDetails} />} />
        <Route path="/company" element={<PrivateRoutes component={AccountDetails} />} />
        <Route path="/userdetail/:id" element={<PrivateRoutes component={UserDetails} />} />
      </Routes>
    </div>
  )
}

export default Router
