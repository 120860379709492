import * as React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/customer/payment/customerPaymentList/Model'
import InactivePaymentDialog from './InactivePaymentDialog'
import DeletePaymentDialog from './DeletePaymentDialog'
import { ModifiedPaymentList } from './Model'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { updateInActivePaymentByIDClearRes } from 'reduxStore/customer/payment/inactivePayment/inactiveCustomerPaymentByIDSlice'
import { deleteCustomerPaymentByIDClearRes } from 'reduxStore/customer/payment/deletePayment/deleteCustomerPaymentByIDSlice'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
import { checkReturnValue } from 'components/Utility'

export default function PaymentList() {
  const customerPaymentList = useAppSelector((state) => state.customerPaymentList)
  const [paymentArray, setPaymentArray] = React.useState([] as ModifiedPaymentList[])
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (customerPaymentList?.customerPaymentList?.results! !== undefined) {
        let paymentObj = customerPaymentList?.customerPaymentList?.results!.map(
          (element: Result) => {
            return {
              id: element?.id,
              paymentName: checkReturnValue(element?.payment_vendor?.display_name),
              name: checkReturnValue(element?.name),
              status: element?.active! !== undefined ? element?.active! : false,
            }
          },
        )
        setPaymentArray(paymentObj)
      } else setPaymentArray([] as ModifiedPaymentList[])
    }
  }, [customerPaymentList])

  const [selectedItem, setSelectedItem] = React.useState('')
  const [showInactivePaymentDialog, setShowInActivePaymentDialog] = React.useState(false)
  const updateDialogState = (id: string) => {
    setSelectedItem(id)
    setShowInActivePaymentDialog(true)
  }

  const updateCompanyDetailsDialogValFromChild = (passedVal: boolean) => {
    setShowInActivePaymentDialog(passedVal)
  }

  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = React.useState(false)
  const updateDeletePaymentDialogState = (id: string) => {
    setSelectedItem(id)
    setShowDeletePaymentDialog(true)
  }

  const updateDeletePaymentDialogValFromChild = (passedVal: boolean) => {
    setShowDeletePaymentDialog(passedVal)
  }

  const [inactivePaymentSuccessMessage, setInactivePaymentSuccessMessage] = React.useState('')
  const handleInactivePaymentSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setInactivePaymentSuccessMessage(message)
  }
  const closeInactivePaymentSnackbar = () => {
    dispatch(updateInActivePaymentByIDClearRes())
    setInactivePaymentSuccessMessage('')
  }
  // For delete payment
  const [deletePaymentSuccessMessage, setDeletePaymentSuccessMessage] = React.useState('')
  const handleDeletePaymentSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setDeletePaymentSuccessMessage(message)
  }
  const closeDeletePaymentSnackbar = () => {
    dispatch(deleteCustomerPaymentByIDClearRes())
    setDeletePaymentSuccessMessage('')
  }

  const columns: GridColDef[] = [
    {
      field: 'paymentName',
      headerName: 'Payment Vendor Name',
      sortable: true,
      hideable: false,
      width: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      hideable: true,
      minWidth: 180,
      flex: 1,
      align: 'left',
    },
    {
      field: 'status',
      headerName: 'Action',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <Switch
            checked={
              params.row.status !== null || params.row.status !== undefined
                ? params.row.status
                : false
            }
            onClick={() =>
              updateDialogState(
                params.row.id !== null || params.row.id !== undefined ? String(params.row.id) : '',
              )
            }
          />
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton onClick={() => updateDeletePaymentDialogState(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        )
      },
    },
  ]

  return (
    <Box>
      {showInactivePaymentDialog ? (
        <InactivePaymentDialog
          itemId={selectedItem}
          inActivePaymentDialogEnable={showInactivePaymentDialog}
          inActivePaymentDialogUpdateState={updateCompanyDetailsDialogValFromChild}
          onSuccessUpadtePasswordMessage={handleInactivePaymentSuccessMessage}
        />
      ) : null}
      {showDeletePaymentDialog ? (
        <DeletePaymentDialog
          itemId={selectedItem}
          deletePaymentDialogEnable={showDeletePaymentDialog}
          deletePaymentDialogUpdateState={updateDeletePaymentDialogValFromChild}
          onSuccessDeletePaymentMessage={handleDeletePaymentSuccessMessage}
        />
      ) : null}
      <Card>
        <CardHeader
          avatar={<CreditCardIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Stack direction={'row'}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Payment Sources:
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <Chip label={paymentArray.length} />
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          {customerPaymentList.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box style={{ height: '405px', width: '100%' }}>
              <DataGridPremium
                  sx={{
                    '@media print': {
                     '.MuiDataGrid-main': {
                         width: 'fit-content',
                         fontSize: '20px',
                         height: 'fit-content',
                         overflow: 'visible',
                          },
                         marginBottom: 100,
                         },
                         '& .MuiDataGrid-footerContainer': {
                           height: '0px',
                           minHeight: '0px',
                       }, 
                     }}
                columns={columns}
                rows={paymentArray}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'paymentName', sort: 'asc' }],
                  },
                }}
                slots={{
                  toolbar: MUIDDCustomToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooterRowCount
                hideFooterSelectedRowCount
                disableRowGrouping
              />
            </Box>
          )}
        </CardContent>
      </Card>
      {inactivePaymentSuccessMessage && (
        <ApplogieSnackbar
          msg={inactivePaymentSuccessMessage}
          type="success"
          alertClose={closeInactivePaymentSnackbar}
        />
      )}

      {deletePaymentSuccessMessage && (
        <ApplogieSnackbar
          msg={deletePaymentSuccessMessage}
          type="success"
          alertClose={closeDeletePaymentSnackbar}
        />
      )}
    </Box>
  )
}
