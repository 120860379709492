import { checkReturnValue } from 'components/Utility'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/account/suspiciousActivityList/SuspiciousActivityModel'
import { ModifiedSuspiciousActivityList } from './Model'

export function AllSuspiciousActivityList() {
  const suspiciousActivityListRes = useAppSelector((state) => state.suspiciousActivityList)
  const [suspiciousActivityList, setSuspiciousActivityList] = useState(
    [] as ModifiedSuspiciousActivityList[],
  )

  // Modify dataset for SuspiciousActivityList table
  useEffect(() => {
    setSuspiciousActivityList(
      checkReturnValue(suspiciousActivityListRes?.accountDetails?.results) !== 'Not Set'
        ? suspiciousActivityListRes?.accountDetails?.results.map((item: Result) => ({
            id: item.id,
            firstName:
              checkReturnValue(item?.user?.first_name) !== 'Not Set'
                ? item?.user?.first_name
                : "Not Set",
            lastName:
              checkReturnValue(item?.user?.last_name) !== 'Not Set' ? item?.user?.last_name : "Not Set",
            email: checkReturnValue(item?.user?.email) !== 'Not Set' ? item?.user?.email : null,
            message: checkReturnValue(item?.message) !== 'Not Set' ? item?.message : null,
            created: item?.created,
            hide: item?.hide,
          }))
        : ([] as ModifiedSuspiciousActivityList[]),
    )
  }, [suspiciousActivityListRes])

  return suspiciousActivityList
}
