import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  addCompanyUser,
  addUserClearMsg,
} from 'reduxStore/customer/companyUser/addCompanyUser/addCompanyUserSlice'
import { AddUserBody, MainBody } from 'reduxStore/customer/companyUser/addCompanyUser/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import { useForm } from 'react-hook-form'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
type Props = {
  dialogEnable?: boolean | any
  updatedDialogState?: boolean | any
}

export default function AddCustomer({ dialogEnable, updatedDialogState }: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  const addedCompanyUserApiResponse = useAppSelector((state) => state.addCompanyUser)
  const [addUserErrorMsg, setAddUserErrorMsg] = React.useState('')

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
    },
  })

  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    updatedDialogState(false)
    dispatch(addUserClearMsg())
  }
  React.useEffect(() => {
    if (addedCompanyUserApiResponse?.addCompanyUserRes?.id !== undefined) {
      setDialogState(false)
      updatedDialogState(false)
    }
    addedCompanyUserApiResponse?.errorMessage !== ''
      ? setAddUserErrorMsg(addedCompanyUserApiResponse?.errorMessage)
      : setAddUserErrorMsg('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCompanyUserApiResponse])

  const addUser = (passedVal: MainBody) => {
    let mainBody = {
      body: passedVal,
      token: Token!,
    } as AddUserBody
    dispatch(addCompanyUser(mainBody))
  }

  React.useEffect(() => {
    setDialogState(dialogEnable)
  }, [dialogEnable])

  const [showCustomerPassword, setShowCustomerPassword] = React.useState(false)
  const handleClickShowCustomerPassword = () => setShowCustomerPassword((show) => !show)
  const handleMouseDownCustomerPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Box>
      <Dialog onClose={handleClose} open={dialogState} fullWidth maxWidth="sm">
        <DialogTitle id="customer-add-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6">
                <b>Add a User:</b>
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(addUser)}>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={0}
                >
                  <TextField
                    id="companyUser_userName"
                    label="Username"
                    placeholder="Please enter username"
                    variant="standard"
                    fullWidth
                    required
                    maxLength={20}
                    {...register('username', {
                      required: 'Username is required',
                      setValueAs: (value) =>
                        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(value.toLowerCase())
                          ? value.toLowerCase()
                          : value,
                      maxLength: {
                        value: 30,
                        message: 'Username contains max 30 characters',
                      },
                    })}
                    error={Boolean(errors.username)}
                    helperText={errors!.username?.message!}
                  />
                </Stack>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body2" fontSize={10}>
                      {watch('username') ? watch('username').length : '0'}/30
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={0}
                >
                  <TextField
                    id="companyUser_userPassword"
                    type={showCustomerPassword ? 'text' : 'password'}
                    label="Password"
                    variant="standard"
                    required
                    fullWidth
                    {...register('password', {
                      required: 'Password is required',
                      setValueAs: (value) => value,
                      maxLength: {
                        value: 20,
                        message: 'Password contains max 20 characters',
                      },
                      minLength: {
                        value: 8,
                        message: 'Password contains min 8 characters',
                      },

                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCustomerPassword}
                            onMouseDown={handleMouseDownCustomerPassword}
                            edge="end"
                          >
                            {showCustomerPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors.password)}
                    helperText={errors!.password?.message!}
                  />
                </Stack>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body2" fontSize={10}>
                      {watch('password') ? watch('password').length : '0'}/20
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="companyUser_userEmail"
                  label="Email"
                  variant="standard"
                  fullWidth
                  {...register('email', {
                    setValueAs: (value) => value,
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors!.email?.message!}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="companyUser_FirstName"
                  label="First Name"
                  variant="standard"
                  fullWidth
                  {...register('first_name', {
                    setValueAs: (value) => value,
                  })}
                  error={Boolean(errors.first_name)}
                  helperText={errors!.first_name?.message!}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="companyUser_LastName"
                  label="Last Name"
                  variant="standard"
                  fullWidth
                  {...register('last_name', {
                    setValueAs: (value) => value,
                  })}
                  error={Boolean(errors.last_name)}
                  helperText={errors!.last_name?.message!}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={0}
                >
                  <TextField
                    id="userPhoneNumber"
                    label="Phone Number"
                    placeholder="123-456-7890"
                    variant="standard"
                    fullWidth
                    required
                    {...register('phone_number', {
                      required: 'Phone Number is required',
                      setValueAs: (value) => value,
                      pattern: {
                        value: /^\d{3}-\d{3}-\d{4}$/,
                        message: 'Invalid Phone number Format',
                      },
                      maxLength: {
                        value: 12,
                        message: 'Phone Number contains max 12 characters',
                      },
                      minLength: {
                        value: 10,
                        message: 'Phone Number contains min 10 characters',
                      },
                    })}
                    error={Boolean(errors.phone_number)}
                    helperText={errors!.phone_number?.message!}
                  />
                </Stack>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body2" fontSize={10}>
                      {watch('phone_number') ? watch('phone_number').length : '0'}/12
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Box py={2}>
            {addUserErrorMsg !== '' ? (
              <Alert
                icon={false}
                severity="error"
                sx={{ mb: 2 }}
                onClose={() => {
                  dispatch(addUserClearMsg())
                }}
              >
                {addUserErrorMsg}
              </Alert>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" onClick={() => handleSubmit(addUser)()}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>Add User</Typography>
              </Grid>
              {addedCompanyUserApiResponse.loading ? (
                <Grid item>
                  <CircularProgress color="inherit" size={18} />
                </Grid>
              ) : null}
            </Grid>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
