import React, { useEffect, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import VendorSpendDeptDetails from '../VendorSpendDept/VendorSpendDeptDetails'
import { useAppDispatch } from 'reduxStore/hooks'
import { VendorDeptDetailsReq } from 'reduxStore/vendorDeptDetails/vendorDeptDetailsModel'
import {
  fetchVendorDeptDetails,
  updateVendorDeptDetailsClearRes,
} from 'reduxStore/vendorDeptDetails/vendorDeptDetailsSlice'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface FilteredDept {
  cost: number | undefined
  projected_cost: number | undefined
  dept_short_name: string | undefined
  dept_name: string | undefined
  department_id: string | undefined
}

type Props = {
  enableDialog?: boolean
  updateDialogState: (data: boolean) => void
  selectedItem?: FilteredDept
  updateSelectedItem: (data: FilteredDept) => void
}

export default function IndividualDeptSpend({
  enableDialog,
  updateDialogState,
  selectedItem,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(false)
  useEffect(() => {
    enableDialog !== undefined && setOpen(enableDialog)
  }, [enableDialog])

  const handleClose = () => {
    setOpen(false)
    updateDialogState(false)
  }
  const [selectedSpendLimit, setSelectedSpendLimit] = useState('10')
  const spendLimit = ['10', '20', '30'] as string[]
  function handleLimitChange(passedVal: string) {
    setSelectedSpendLimit(passedVal)
    console.log('passedVal', passedVal)
    let deptDetailsParam = {
      deptId: String(selectedItem?.department_id),
      length: passedVal,
      token: Token!,
    } as VendorDeptDetailsReq
    dispatch(fetchVendorDeptDetails(deptDetailsParam))
    dispatch(updateVendorDeptDetailsClearRes())
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0.5}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item>
              <IconButton color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">
                Vendor Spend Department-
                {selectedItem?.dept_name}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Limit Graph to Top</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedSpendLimit}
                  onChange={(e) => handleLimitChange(e?.target.value)}
                  label="Limit Graph to Top"
                  disabled={spendLimit.length === 0 ? true : false}
                >
                  {spendLimit.length > 0
                    ? spendLimit.map(function (option: string) {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <VendorSpendDeptDetails />
      </DialogContent>
    </Dialog>
  )
}
