import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { checkReturnValue } from 'components/Utility'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  EditCompanyUserBody,
  EditCompanyUserMainBody,
} from 'reduxStore/customer/companyUser/editCompanyUser/editCompanyUserModel'
import { editCompanyUserApi } from 'reduxStore/customer/companyUser/editCompanyUser/editCompanyUserSlice'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
interface Props {
  item: any
}
export default function EditCompanyUser({ item }: Props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [width, setWidth] = useState('')
  useEffect(() => {
    setWidth(fullScreen ? '100%' : '30vw')
  }, [fullScreen])
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const editCompanyUserValue = useAppSelector((state) => state.editCompanyUserRes)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
    },
  })
  useEffect(() => {
    setValue('username', checkReturnValue(item.userName) !== 'Not Set' ? item.userName : '')
    setValue('email', checkReturnValue(item.email) !== 'Not Set' ? item.email : '')
    setValue('first_name', checkReturnValue(item.firstName) !== 'Not Set' ? item.firstName : '')
    setValue('last_name', checkReturnValue(item.lastName) !== 'Not Set' ? item.lastName : '')
    setValue(
      'phone_number',
      checkReturnValue(item?.phoneNumber) !== 'Not Set' ? item?.phoneNumber : '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])
  const onSubmit = (data: any) => {
    let editApiCallBody = {} as EditCompanyUserBody
    editApiCallBody = {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.password,
      phone_number: data.phone_number,
      username: data.username,
    } as EditCompanyUserBody
    if (data.password === '') {
      delete editApiCallBody.password
      let mainBody = {
        id: item.userId,
        token: Token!,
        body: editApiCallBody,
      } as EditCompanyUserMainBody
      dispatch(editCompanyUserApi(mainBody))
    } else {
      let mainBody = {
        id: item.userId,
        token: Token!,
        body: editApiCallBody,
      } as EditCompanyUserMainBody
      dispatch(editCompanyUserApi(mainBody))
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ py: 2, height: '100%', boxSizing: 'border-box', backgroundColor: '#f2f2f2' }}
        // px={fullScreen ? 2 : 0}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ minWidth: width, maxWidth: width }}
        >
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" gutterBottom component="div">
                Edit User
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ minWidth: width, maxWidth: width }}
            >
              <Divider style={{ color: '#000000', width: '100%' }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ minWidth: width, maxWidth: width }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
              >
                <TextField
                  id="company-user-userName"
                  label="Username"
                  placeholder="Please enter username"
                  variant="standard"
                  fullWidth
                  required
                  maxLength={20}
                  {...register('username', {
                    required: 'Username is required',
                    setValueAs: (value) =>
                      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(value.toLowerCase())
                        ? value.toLowerCase()
                        : value,
                    maxLength: {
                      value: 30,
                      message: 'Username contains max 30 characters',
                    },
                  })}
                  error={Boolean(errors.username)}
                  helperText={errors!.username?.message!}
                  sx={{ mt: 2 }}
                />
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body2" fontSize={10}>
                      {watch('username') ? watch('username').length : '0'}/30
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="company-user-email"
                label="Email"
                variant="standard"
                fullWidth
                required
                {...register('email', {
                  setValueAs: (value) => value,
                })}
                error={Boolean(errors.email)}
                helperText={errors!.email?.message!}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="company-user-first-name"
                label="First Name"
                variant="standard"
                fullWidth
                required
                {...register('first_name', {
                  setValueAs: (value) => value,
                })}
                error={Boolean(errors.first_name)}
                helperText={errors!.first_name?.message!}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="company-user-last-name"
                label="Last Name"
                variant="standard"
                fullWidth
                required
                {...register('last_name', {
                  setValueAs: (value) => value,
                })}
                error={Boolean(errors.last_name)}
                helperText={errors!.last_name?.message!}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
              >
                <TextField
                  id="company-user-phone-number"
                  label="Phone Number"
                  placeholder="123-456-7890"
                  variant="standard"
                  fullWidth
                  required
                  {...register('phone_number', {
                    required: 'Phone Number is required',
                    setValueAs: (value) => value,
                    pattern: {
                      value: /^\d{3}-\d{3}-\d{4}$/,
                      message: 'Invalid Phone number Format',
                    },
                    maxLength: {
                      value: 12,
                      message: 'Phone Number must be contains max 12 characters',
                    },
                    minLength: {
                      value: 12,
                      message: 'Phone Number must be contains min 12 characters',
                    },
                  })}
                  error={Boolean(errors.phone_number)}
                  helperText={errors!.phone_number?.message!}
                />
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="body2" fontSize={10}>
                      {watch('phone_number') ? watch('phone_number').length : '0'}/12
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                  <Button
                    onClick={() => handleSubmit(onSubmit)()}
                    variant="contained"
                    fullWidth
                    disabled={editCompanyUserValue.loading}
                  >
                    {editCompanyUserValue.loading === true ? (
                      <Stack direction="row" spacing={1}>
                        <Typography>Save</Typography>
                        <Box pl={1}>
                          <CircularProgress color="inherit" size={18} />
                        </Box>
                      </Stack>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
