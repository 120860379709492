import { Box,IconButton, Link } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CustomDetailPanelToggle } from 'components/Subscripiton/CustomDetailPanelToggle'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { CustomTooltip } from './HeaderToolTip'
import { renderApplogieDate } from 'components/Utility'

export function BreachesTableColumn() {
  const location = useLocation()
  const columns: GridColDef[] = [
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: true,
      hideable: false,
      width: 280,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/breachdetail/${params.row.vendorID}`} variant='subtitle2' underline="none"> 
          <b>{params.row.vendorName}</b></Link>
        )
      },
    },
    {
      field: 'userCount',
      headerName: 'User Count',
      sortable: true,
      hideable: false,
      width: 120,
      align: 'left',
    },
    {
      field: 'breachDate',
      headerName: 'Breach Date',
      sortable: true,
      hideable: true,
      width: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{renderApplogieDate(params.row.breachDate) !== null && renderApplogieDate(params.row.breachDate)}</Box>
      ),
    },

    {
      field: 'password',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {params.row.password ? (
            <IconButton size="small">
              <CheckCircleIcon fontSize="small" sx={{ color: '#30a289' }} />
            </IconButton>
          ) : null}
        </Box>
      ),
      renderHeader: (_params: GridColumnHeaderParams) => (
        <CustomTooltip title="This breach contained password breaches" placement="bottom" arrow>
          <VisibilityOffIcon style={{ color: '#0000008a' }} />
        </CustomTooltip>
      ),
    },
    {
      field: 'creditCard',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
      <Box>
        {params.row.password ? (
          <IconButton size="small">
            <CheckCircleIcon fontSize="small" sx={{ color: '#30a289' }} />
          </IconButton>
        ) : null}
      </Box>
      ),
      renderHeader: (_params: GridColumnHeaderParams) => (
        <CustomTooltip title="This breach contained credit card breaches" placement="bottom" arrow>
          <CreditCardIcon style={{ color: '#0000008a' }} />
        </CustomTooltip>
      ),
    },
    {
      field: 'authToken',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
      <Box>
        {params.row.passwordHint ? (
          <IconButton size="small">
            <CheckCircleIcon fontSize="small" sx={{ color: '#30a289' }} />
          </IconButton>
        ) : null}
      </Box>
      ),
      renderHeader: (_params: GridColumnHeaderParams) => (
        <CustomTooltip
          title="This breach contained authentication token breaches"
          placement="bottom"
          arrow
        >
          <LocalPoliceIcon style={{ color: '#0000008a' }} />
        </CustomTooltip>
      ),
    },
    {
      field: 'passwordHint',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
      <Box>
        {params.row.passwordHint ? (
          <IconButton size="small">
            <CheckCircleIcon fontSize="small" sx={{ color: '#30a289' }} />
          </IconButton>
        ) : null}
      </Box>
      ),
      renderHeader: (_params: GridColumnHeaderParams) => (
        <CustomTooltip
          title="This breach contained password hint breaches"
          placement="bottom"
          arrow
        >
          <LocalPhoneIcon style={{ color: '#0000008a' }} />
        </CustomTooltip>
      ),
    },
    {
      field: 'securityQA',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      flex:1,
      renderCell: (params: GridCellParams) => (
      <Box>
        {params.row.securityQA ? (
          <IconButton size="small">
            <CheckCircleIcon fontSize="small" sx={{ color: '#30a289' }} />
          </IconButton>
        ) : null}
      </Box>
      ),
      renderHeader: (_params: GridColumnHeaderParams) => (
        <CustomTooltip
          title="This breach contained security question and answer breaches"
          placement="bottom"
          arrow
        >
          <KeyboardIcon />
        </CustomTooltip>
      ),
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'left',
      width: 180,
      renderCell: (params) =>
        location.pathname === '/dashboard' ? null : (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
