import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results } from './Model'
import url from 'APIConfig'
import { checkReturnValue, renderApplogieDate } from 'components/Utility'
import dayjs from 'dayjs'

type InitialState = {
  loading: boolean
  subscriptions: Results
  filteredSubscription: any
  error: string
}
const initialState: InitialState = {
  loading: false,
  subscriptions: {} as Results,
  filteredSubscription: [] as any[],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchSubscription = createAsyncThunk<Results, string>(
  'subscription/fetchSubscription',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers/accounts/', { headers })
      .then((response) => response.data)
  },
)

const subscriptionsSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    filterActiveSubscriptionByMonthWiseYear(state, action) {
      if (state.subscriptions.results !== undefined) {
        let array = state.subscriptions.results.map((item: any) => {
          return {
            id: 'id' in item ? item.id : 0,
            alert: 'alert_count' in item ? Number(item.alert_count) : 0,
            accountId:
              checkReturnValue(item.account_id) !== 'Not Set'
                ? checkReturnValue(item.account_id)
                : 0,
            name: checkReturnValue(item.vendor_name) !== 'Not Set' ? item.vendor_name : null,
            account:
              checkReturnValue(item.account_number) !== 'Not Set' ? item.account_number : null,
            renewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? dayjs(checkReturnValue(item.renewal_date)).format('YYYY-MM-DD')
                : null,
            modifiedRenewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? renderApplogieDate(checkReturnValue(item.renewal_date))
                : null,
            modifiedFilteredRenewalDate:
              checkReturnValue(item.renewal_date) !== 'Not Set'
                ? dayjs(checkReturnValue(item.renewal_date)).format('MMMM YYYY')
                : null,
            userName:
              checkReturnValue(item?.owner?.user?.username) !== 'Not Set'
                ? item?.owner?.user?.username
                : null,
            userEmail:
              checkReturnValue(item?.owner?.user?.email) !== 'Not Set'
                ? item?.owner?.user?.email
                : null,
            department:
              checkReturnValue(item?.department?.name) !== 'Not Set'
                ? item?.department?.name
                : null,
            yearlySpend:
              checkReturnValue(item?.yearly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_spend))
                : 0,
            yearlyBudget:
              checkReturnValue(item?.yearly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.yearly_budget))
                : 0,
            quarterlySpend:
              checkReturnValue(item?.quarterly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_spend))
                : 0,
            quarterlyBudget:
              checkReturnValue(item?.quarterly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.quarterly_budget))
                : 0,
            monthlySpend:
              checkReturnValue(item?.monthly_spend) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_spend))
                : 0,
            monthlyBudget:
              checkReturnValue(item?.monthly_budget) !== 'Not Set'
                ? Number(checkReturnValue(item?.monthly_budget))
                : 0,
            status: checkReturnValue(item?.status?.name) !== 'Not Set' ? item?.status?.name : null,
            owner:
              checkReturnValue(item?.owner) !== 'Not Set' ? checkReturnValue(item?.owner) : null,
            departmentList:
              checkReturnValue(item?.department) !== 'Not Set'
                ? checkReturnValue(item?.department)
                : null,
            description:
              checkReturnValue(item?.description) !== 'Not Set'
                ? checkReturnValue(item?.description)
                : '',
            monthly_projected_cost:
              checkReturnValue(item?.monthly_projected_cost) !== 'Not Set'
                ? checkReturnValue(item?.monthly_projected_cost)
                : null,
            aliasName:
              checkReturnValue(item?.vendor_alias) !== 'Not Set'
                ? checkReturnValue(item?.vendor_alias) !== ''
                  ? checkReturnValue(item?.vendor_alias)
                  : '---'
                : '---',
          }
        }) as any
        let data = array.filter((item: any) => item.modifiedFilteredRenewalDate === action.payload)
        state.filteredSubscription = data
      }
    },
    clearFilteredActiveSubscriptionByMonthWiseYear(state) {
      state.filteredSubscription = [] as any[]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchSubscription.fulfilled, (state, action: PayloadAction<Results>) => {
      state.loading = false
      state.subscriptions = action.payload
      state.error = ''
    })
    builder.addCase(fetchSubscription.rejected, (state, action) => {
      state.loading = false
      state.subscriptions = {} as Results
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export const {
  filterActiveSubscriptionByMonthWiseYear,
  clearFilteredActiveSubscriptionByMonthWiseYear,
} = subscriptionsSlice.actions
export default subscriptionsSlice.reducer
