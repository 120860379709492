import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)

interface Props {
  data: any[]
  year: any
}

export default function IndividualMonthlyVendorChart({ data, year }: Props) {
  const [xAxisZoom, setXAxisZoom] = useState(undefined as any)
  const [xAxisYearZoom, setXAxisYearZoom] = useState(undefined as any)

  useEffect(() => {
    let root = am5.Root.new('accountDetailsSpendChartDiv')
    var modal = am5.Modal.new(root, {
      content: 'Monthly Vendor Spend chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])

    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    })

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      }),
    )

    chart.children.unshift(
      am5.Label.new(root, {
        text: 'Monthly Vendor Spend and License Renewal',
        fontSize: 20,
        fontWeight: '600',
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 10,
      }),
    )

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        //behavior: 'zoomX',
      }),
    )
    cursor.lineY.set('visible', false)

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        markUnitChange: false,
        gridIntervals: [
          { timeUnit: 'month', count: 1 },
          //{ timeUnit: 'month', count: 3 },
        ],
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        tooltipDateFormat: 'MMMM, yyyy',
      }),
    )
    // make the axis accessible
    setXAxisZoom(xAxis)

    // second date access for the quarters
    let xAxisYear = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 3 },
        markUnitChange: false,
        gridIntervals: [{ timeUnit: 'month', count: 3 }],
        dateFormats: { month: '                Qq yyyy' },
        renderer: am5xy.AxisRendererX.new(root, {}),
        //x: am5.percent(10),
      }),
    )
    // make the axis accessible
    setXAxisYearZoom(xAxisYear)

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#0a",
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: false,
          minGridDistance: 40,
        }),
      }),
    )

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Monthly Spend',
        fontSize: 12,
        fontWeight: 'bold',
        y: am5.p50,
        centerX: am5.p50,
      }),
    )

    let spendBar = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Spend',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    spendBar.columns.template.setAll({
      width: am5.percent(30),
      fillOpacity: 1,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    spendBar.data.processor = am5.DataProcessor.new(root, {
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    let forecastBar = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Forecast',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'budget',
        valueXField: 'date',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    forecastBar.columns.template.setAll({
      width: am5.percent(65),
      fillOpacity: 0.3,
      strokeOpacity: 0,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    let series0 = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        stroke: am5.Color.fromString('#20acd4'),
      }),
    )
    series0.strokes.template.setAll({
      strokeWidth: 2,
    })

    series0.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          stroke: series0.get('fill'),
          strokeWidth: 2,
          fill: root.interfaceColors.get('background'),
          radius: 2,
        }),
      })
    })

    // new series (with the same data) to be linked with the second date axis
    var seriesY = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxisYear,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        forceHidden: true,
      }),
    )

    let scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 50,
        background: am5.Rectangle.new(root, {
          fill: am5.Color.fromString('#DEECF3'),
          fillOpacity: 0.7,
        }),
      }),
    )

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'month',
          count: 1,
        },
        visible: false,
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    )

    // second date access for the year
    let sbSecondDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 3 },
        markUnitChange: true,
        visible: false,
        gridIntervals: [{ timeUnit: 'month', count: 3 }],
        dateFormats: { month: 'Qq' },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    )

    let sbValueAxis0 = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )
    let sbSeriesForecast = scrollbar.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        valueYField: 'budget',
        valueXField: 'date',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // make the scrollbar columns more similar to the chart
    sbSeriesForecast.columns.template.setAll({
      width: am5.percent(70),
      fillOpacity: 0.2,
      strokeOpacity: 0,
      cornerRadiusTL: 2,
      cornerRadiusTR: 2,
    })

    sbSeriesForecast.columns.template.setAll({ fillOpacity: 0.5, strokeOpacity: 1 })

    let sbSeriesSpend = scrollbar.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // make the scrollbar columns more similar to the chart
    sbSeriesSpend.columns.template.setAll({
      width: am5.percent(30),
      fillOpacity: 1,
      strokeOpacity: 0,
      cornerRadiusTL: 2,
      cornerRadiusTR: 2,
    })

    sbSeriesSpend.columns.template.setAll({ fillOpacity: 0.5, strokeOpacity: 1 })

    let sbSeriesSpendLine = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    let sbSeriesY = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        visible: false,
        xAxis: sbSecondDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // add start and end labels on the scrollbar grips
    let startLabel = scrollbar.startGrip.children.push(
      am5.Label.new(root, {
        isMeasured: false,
        width: 100,
        fill: am5.color(0x000000),
        centerX: 50,
        centerY: 35,
        x: am5.p50,
        y: 0,
        textAlign: 'center',
        populateText: true,
      }),
    )

    if (data.length !== 0) {
      scrollbar.on('start', function (position) {
        setTimeout(function () {
          startLabel.set(
            'text',
            root.dateFormatter.format(xAxis.positionToDate(Number(position)), 'Qq yyyy'),
          )
        }, 50)
      })
    }

    let endLabel = scrollbar.endGrip.children.push(
      am5.Label.new(root, {
        isMeasured: false,
        width: 100,
        fill: am5.color(0x000000),
        centerX: 50,
        centerY: 35,
        x: am5.p50,
        y: 0,
        textAlign: 'center',
        populateText: true,
      }),
    )

    if (data.length !== 0) {
      scrollbar.on('end', function (position) {
        setTimeout(function () {
          endLabel.set(
            'text',
            root.dateFormatter.format(xAxis.positionToDate(Number(position)), 'Qq yyyy'),
          )
        }, 50)
      })
    }

    let legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.p50,
        centerX: am5.p50,
        marginTop: 10,
      }),
    )
    legend.valueLabels.template.setAll({
      width: 90,
      textAlign: 'right',
    })
    legend.data.setAll([forecastBar, spendBar])

    if (data.length === 0) {
      modal.open()
    } else {
        modal.close()
    }

    spendBar.data.setAll(data)
    forecastBar.data.setAll(data)
    // set the data and settings for the year axis
    seriesY.data.setAll(data)

    xAxis.get('renderer').grid.template.setAll({
      location: 0.5,
    })

    xAxisYear.get('renderer').grid.template.setAll({
      //location: 0.5,
    })

    xAxisYear.get('renderer').labels.template.setAll({
      fontWeight: 'bold',
      //location: 0.5,
      //dx: (xAxis.width() / 12) * 1.5,
    })

    xAxisYear.get('renderer').grid.template.setAll({
      strokeOpacity: 2,
    })

    xAxisYear.get('renderer').ticks.template.setAll({
      //visible: false,
      strokeOpacity: 1,
      length: 50,
      dy: -25,
      location: 0.5,
    })

    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'MonthlyVendorSpend',
      dataSource: data,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    series0.data.setAll(data)
    sbSeriesForecast.data.setAll(data)
    sbSeriesSpend.data.setAll(data)
    sbSeriesSpendLine.data.setAll(data)
    sbSeriesY.data.setAll(data)

    series0.appear(1000)
    chart.appear(1000, 100)

    return () => root.dispose()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // when the graph is loaded, the x axes will zoom to the current calendar year
  xAxisZoom !== undefined &&
    xAxisZoom.zoomToDates(new Date(Number(year.label), 0), new Date(Number(year.label), 12))

  xAxisYearZoom !== undefined &&
    xAxisYearZoom.zoomToDates(new Date(Number(year.label), 0), new Date(Number(year.label), 12))

  return (
    <Box
      id="accountDetailsSpendChartDiv"
      style={{ minWidth: '100%', height: '50vh'}}
    />
  )
}
