import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
// import { AllActiveSubscriptionList } from 'components/Subscripiton/AllActiveSubscriptionList'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  clearFilteredActiveSubscriptionByMonthWiseYear,
  filterActiveSubscriptionByMonthWiseYear,
} from 'reduxStore/subcription/Active/subscriptionSlice'

interface VendorSpendChart {
  date: string
  spend: number
  budget: number
  year: string
  modifiedYear: string
  id?: number
  renewalNo?: number
}

type VendorRenewalListProps = {
  enableRenewalDialog: boolean
  updateRenewalDialogState: (value: boolean) => void
  renewalDateItem: VendorSpendChart
  updateRenewalDateItem: (value: VendorSpendChart) => void
}
export default function VendorRenewalList({
  enableRenewalDialog,
  updateRenewalDialogState,
  renewalDateItem,
  updateRenewalDateItem,
}: VendorRenewalListProps) {
  const [open, setOpen] = React.useState(false)
  useEffect(() => {
    enableRenewalDialog ? setOpen(true) : setOpen(false)
  }, [enableRenewalDialog])
  const handleClose = () => {
    setOpen(false)
    updateRenewalDialogState(false)
    updateRenewalDateItem({} as VendorSpendChart)
    dispatch(clearFilteredActiveSubscriptionByMonthWiseYear())
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const columns: GridColDef[] = [
    {
      field: 'modifiedRenewalDate',
      headerName: 'Renewal Date',
      sortable: true,
      hideable: true,
      width: 110,
      align: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      hideable: false,
      minWidth: 180,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/detail/${params.row.accountId}`)
            }}
          >
            {params.row.aliasName !== '---' ? params.row.aliasName : params.row.name}
          </Button>
        )
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      sortable: true,
      hideable: true,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <>{params.row.userEmail !== 'Not Set' && params.row.userEmail && params.row.userEmail}</>
      ),
    },
  ]
  const dispatch = useAppDispatch()
  const subsValue = useAppSelector((state) => state.subscription)

  const [renewalList, setRenewalList] = useState([] as any[])
  useEffect(() => {
    setRenewalList(subsValue?.filteredSubscription)
  }, [subsValue])
  useEffect(() => {
    renewalDateItem?.modifiedYear !== undefined &&
      dispatch(filterActiveSubscriptionByMonthWiseYear(renewalDateItem?.modifiedYear))
  }, [dispatch, renewalDateItem])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            Show renewals here for {renewalDateItem?.year} -
            {` ${monthNames.indexOf(renewalDateItem?.modifiedYear?.split(' ')[0]) + 1}`}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: 'calc(100vh - 250px)' }}>
          <DataGridPremium
            columns={columns}
            rows={renewalList}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            hideFooter
            disableRowGrouping
            disableAggregation
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No Renewals are available' }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
