import React, { useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { Result } from 'reduxStore/users/Active/Model'
import { renderApplogieDate } from 'components/Utility'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { useAppSelector } from 'reduxStore/hooks'

type Props = {
  item?: Result | any
}

export default function ShowAccountDetails({ item }: Props) {
  const activeUserVal = useAppSelector((state) => state.activeUsers)
  const accountsTableColumn: GridColDef[] = [
    {
      field: 'accountVendorName',
      headerName: 'Account',
      sortable: true,
      hideable: true,
      width: 280,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
            {params.row.accountVendorName !== "" ?
              <Link href={`/detail/${params.row.accountId}`} variant='subtitle2' underline="none">
                <b>{params.row.accountVendorName}</b></Link>
              : null}
          </Box>
        )
      },
    },
    {
      field: 'accountNumber',
      headerName: 'Number',
      sortable: true,
      hideable: true,
      width: 140,
      align: 'left',
    },
    {
      field: 'accountStatus',
      headerName: 'Active',
      sortable: true,
      hideable: true,
      width: 100,
      align: 'left',
    },
    {
      field: 'accountLastLogin',
      headerName: 'Last Login',
      sortable: true,
      hideable: true,
      width: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{renderApplogieDate(params.row.accountLastLogin) !== null && renderApplogieDate(params.row.accountLastLogin)}</Box>
      ),
    },
    {
      field: 'accountLastUpdate',
      headerName: 'Last Updated',
      sortable: true,
      hideable: true,
      width: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{renderApplogieDate(params.row.accountLastUpdate) !== null && renderApplogieDate(params.row.accountLastUpdate)}</Box>
      ),
    },
  ]

  const [modifiedUserAccountList, setModifiedUserAccountList] = React.useState([] as any[])
  useEffect(() => {
    if (item.account !== undefined || item.account !== "") {
      let userAccountsObj = item.account.map((element: any) => {
        return {
          id: element.id,
          accountId: element?.account?.id,
          accountVendorName:
            element?.account?.vendor?.name !== undefined && element?.account?.vendor?.name !== ''
              ? element?.account?.vendor?.name
              : 'Not Set',
          accountNumber:
            element?.account?.account_number !== undefined && element?.account?.account_number !== ''
              ? element.account.account_number
              : 'Not Set',
          accountStatus: element?.active !== undefined
            ? element.active
            : 'Not Set',
          accountLastLogin:
            element?.last_login !== undefined && element?.last_login !== ''
              ? element.last_login
              : 'Not Set',
          accountLastUpdate:
            element?.last_updated !== undefined && element?.last_updated !== ''
              ? element.last_updated
              : 'Not Set',
        }
      })
      setModifiedUserAccountList(userAccountsObj)
    }
  }, [item])

  return (
    <Grid
      container
      direction="column"
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          pt={2}
        >
          <Grid item>
            <AccountBalanceWalletIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" gutterBottom>
              Accounts
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ background: 'black' }}/>
        <Card sx={{ margin: 1 }}>
          <CardContent>
            <Box sx={{height: '20vh', overflow: 'auto' }}>
              <DataGridPremium
                columns={accountsTableColumn}
                rows={modifiedUserAccountList}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooter
                disableRowGrouping
                disableAggregation
                disableRowSelectionOnClick
                loading={activeUserVal.loading}
                localeText={{ noRowsLabel: 'No Accounts Details available' }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
