import React, { useState, useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  DialogProps,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  UpdatePasswordBody,
  UpdatePasswordReqBody,
} from 'reduxStore/updatePassword/UpdatePasswordModel'
import {
  updatePassword,
  updatePasswordClearRes,
} from 'reduxStore/updatePassword/updatePasswordSlice'
import { useForm } from 'react-hook-form'

type Props = {
  dialogEnable?: boolean
  updatedDialogState: (value: boolean) => void
}

export default function UpdatePassword({ dialogEnable, updatedDialogState }: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const updatePassVal = useAppSelector((state) => state.updatePassword)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  const [apiErrMsq, setApiErrMsq] = useState('')

  const [dialogState, setDialogState] = useState(false)
  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setDialogState(false)
      updatedDialogState(false)
      dispatch(updatePasswordClearRes())
    }
  }

  useEffect(() => {
    setDialogState(dialogEnable!)
    dispatch(updatePasswordClearRes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogEnable])

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show)
  const handleMouseDownCurrentPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show)
  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  //handle button click of login form
  const onSubmit = async (data: any) => {
    setApiErrMsq('')
    let updatePasswordBody = {
      old_password: data?.currentPassword,
      new_password: data?.newPassword,
    } as UpdatePasswordBody
    let mainReqBody = {
      payload: updatePasswordBody,
      token: Token!,
    } as UpdatePasswordReqBody
    navigator.onLine
      ? dispatch(updatePassword(mainReqBody))
      : setApiErrMsq('You are currently offline')
  }
  React.useEffect(() => {
    if (updatePassVal?.succssMessage !== '') {
      setDialogState(false)
      updatedDialogState(false)
      dispatch(updatePasswordClearRes())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePassVal])

  return (
    <Dialog onClose={handleClose} open={dialogState} fullWidth maxWidth="xs">
      <DialogTitle>Update Password</DialogTitle>
      <DialogContent dividers>
        <form id="update-password" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item>
              <TextField
                id="currentPassword"
                label="Current Password"
                variant="standard"
                fullWidth
                type={showCurrentPassword ? 'text' : 'password'}
                {...register('currentPassword', {
                  required: 'Current Password is required',
                  setValueAs: (value) => value,
                })}
                error={Boolean(errors.currentPassword)}
                helperText={errors!.currentPassword?.message!}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownCurrentPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="newPassword"
                label="New Password"
                variant="standard"
                fullWidth
                {...register('newPassword', {
                  required: 'Current Password is required',
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                    message: 'Password must be 8 characters, at least one letter and one number',
                  },
                  validate: (value) =>
                    value !== watch('currentPassword') ||
                    'New password must be different from Current Password',
                  setValueAs: (value) => value,
                })}
                type={showNewPassword ? 'text' : 'password'}
                error={Boolean(errors.newPassword)}
                helperText={errors!.newPassword?.message!}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                variant="standard"
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                {...register('confirmPassword', {
                  required: 'Confirm Password is required',
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                    message: 'Password must be 8 characters, at least one letter and one number',
                  },
                  validate: (value) => value === watch('newPassword') || 'Password does not match',
                  setValueAs: (value) => value,
                })}
                error={Boolean(errors.confirmPassword)}
                helperText={errors!.confirmPassword?.message!}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {apiErrMsq !== '' ? (
                <Grid item>
                  <Typography sx={{ color: '#c62828' }}>{apiErrMsq}</Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" onClick={() => handleSubmit(onSubmit)()}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>Update Password</Grid>
                {updatePassVal.loading ? (
                  <Grid item>
                    <CircularProgress color="inherit" size={20} thickness={4} />
                  </Grid>
                ) : null}
              </Grid>
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                // Close Dialog
                setDialogState(false)
                updatedDialogState(false)
                dispatch(updatePasswordClearRes()) // Clear update password api response from store for previous stored value
              }}
              style={{ backgroundColor: '#DCDCDC' }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
