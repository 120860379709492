import { DataGridPremium, GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { OptimizationTableColumn } from 'components/Optimization/OptimizationTableColumn'
import DataGridTableHeader from 'components/Subscripiton/DataGridTableHeader'
import React, { useEffect, useState } from 'react'
import { TableListData } from './TableListData'
import { useLocation } from 'react-router-dom'
export default function OptimizationTable() {
  const location = useLocation()
  const [inVisibleColumnModel, setInVisibleColumnModel] = useState<GridColumnVisibilityModel>()
  useEffect(() => {
    location?.pathname === '/dashboard'
      ? setInVisibleColumnModel({ createDate: false, message: false })
      : setInVisibleColumnModel({})
  }, [location])

  return (
    <DataGridPremium
    sx={{
      '@media print': {
       '.MuiDataGrid-main': {
           width: 'fit-content',
           fontSize: '20px',
           height: 'fit-content',
           overflow: 'visible',
            },
           marginBottom: 100,
           },
           '& .MuiDataGrid-footerContainer': {
             height: '0px',
             minHeight: '0px',
         }, 
       }}
      columns={OptimizationTableColumn()}
      rows={TableListData()}
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: 'vendorName', sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: DataGridTableHeader,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooterRowCount
      hideFooterSelectedRowCount
      columnVisibilityModel={inVisibleColumnModel}
      density={location.pathname === '/dashboard' ? 'compact' : 'comfortable'}
      disableRowGrouping
      localeText={{ noRowsLabel: 'No Optimizations are available' }}
    />
  )
}
