import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { VendorDeptUserListRes } from './vendorDeptUserListModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  vendorDeptUserListRes: VendorDeptUserListRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  vendorDeptUserListRes: {} as VendorDeptUserListRes,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchVendorDeptUserList = createAsyncThunk<VendorDeptUserListRes, string>(
  'fetchVendorCategory/fetchVendorDeptUserList',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers/usage/department-usercount/', { headers })
      .then((response) => response.data)
  },
)

const fetchVendorDeptUserListSlice = createSlice({
  name: 'fetchVendorCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVendorDeptUserList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchVendorDeptUserList.fulfilled, (state, action: PayloadAction<VendorDeptUserListRes>) => {
      state.loading = false
      state.vendorDeptUserListRes = action.payload
      state.error = ''
    })
    builder.addCase(fetchVendorDeptUserList.rejected, (state, action) => {
      state.loading = false
      state.vendorDeptUserListRes = {} as VendorDeptUserListRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchVendorDeptUserListSlice.reducer
