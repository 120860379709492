import React, { useEffect } from 'react'
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  updateCustomerDetailsClearRes,
  updateCustomerDetailsId,
} from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdSlice'
import {
  Name,
  UpdateCustomerDetailsIdBody,
} from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdModel'
import { fetchCustomerDetails } from 'reduxStore/customer/customer/customerSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { useForm } from 'react-hook-form'
import { checkReturnValue } from 'components/Utility'

export default function CompanyDetails() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const accountDetailsById = useAppSelector((state) => state.customerDetails)
  const updateAccountDetailsById = useAppSelector((state) => state.updateCustomerDetailsById)

  const [customerId, setCustomerId] = React.useState(0)

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  })

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showCompanydetailsMsg, setShowCompanydetailsMsg] = React.useState('')
  const closeCompanyDetailsSnackbar = () => {
    dispatch(updateCustomerDetailsClearRes())
    setShowCompanydetailsMsg('')
  }

  useEffect(() => {
    if (updateAccountDetailsById?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowCompanydetailsMsg(updateAccountDetailsById?.succssMessage)
    }
    if (updateAccountDetailsById?.error !== '') {
      if (updateAccountDetailsById?.error) {
        setAPIMsgType('error')
        setShowCompanydetailsMsg(updateAccountDetailsById?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccountDetailsById])

  React.useEffect(() => {
    setValue(
      'name',
      checkReturnValue(accountDetailsById?.customerDetails[0]?.name) !== 'Not Set'
        ? accountDetailsById?.customerDetails[0]?.name
        : '',
    )
    setCustomerId(
      checkReturnValue(accountDetailsById?.customerDetails[0]?.id) !== 'Not Set'
        ? accountDetailsById?.customerDetails[0]?.id
        : 0,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetailsById])

  //handle button click of login form
  const onSubmit = async (data: any) => {
    // Update Company Name Req Body
    const name = {
      name: data.name,
    } as Name
    let payload = {
      id: String(customerId),
      name: name,
      token: Token!,
    } as UpdateCustomerDetailsIdBody
    await dispatch(updateCustomerDetailsId(payload))
    await dispatch(fetchCustomerDetails(Token!))
  }

  return (
    <Box>
      <Card sx={{ minHeight: '270px', maxHeight: '320px' }}>
        <CardHeader
          avatar={<ApartmentIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack direction={'row'}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Company Details
                </Typography>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          {accountDetailsById.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <form id="company-details-form" onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    id="companyDetailsName-id"
                    label="Name"
                    variant="standard"
                    fullWidth
                    {...register('name', {
                      required: 'Name is required',
                      setValueAs: (value) => value,
                    })}
                    error={Boolean(errors.name)}
                    helperText={errors!.name?.message!}
                  />
                </form>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <Button
                variant="contained"
                type="submit"
                id="company-details-form"
                onClick={() => handleSubmit(onSubmit)()}
                style={{ marginTop: '1em', backgroundColor: '#30a289', textTransform: 'none' }}
              >
                <Typography>Save</Typography>
                {updateAccountDetailsById.loading === true ? (
                  <Box pl={2}>
                    <CircularProgress color="inherit" size={18} />
                  </Box>
                ) : null}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <ApplogieSnackbar
        msg={showCompanydetailsMsg}
        type={apiMsgType}
        alertClose={closeCompanyDetailsSnackbar}
      />
    </Box>
  )
}
