import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
} from '@mui/material'
import * as React from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { checkReturnValue } from 'components/Utility'
import { AccountDetailsLicenseModelReq, MsLicenseResult } from 'reduxStore/account/microsoft/MicrosoftUserLicenseModel'
import { fetchMicrosoftAccountDetailsLicenseUserList } from 'reduxStore/account/microsoft/MicrosoftUserLicenseSlice'
import { useParams } from 'react-router-dom'
type Props = {
  msLicenseDialogEnable?: boolean | any
  msLicenseDialogUpdateState: (value: boolean) => void
}

export default function MicrosoftLicenseuserDialog({ msLicenseDialogEnable, msLicenseDialogUpdateState }: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    setDialogState(false)
    msLicenseDialogUpdateState(false)
  }
  const microsoftLicenseuserListValue = useAppSelector((state) => state.microsoftUserLicense)
  const [microsoftLicenseUserList, setMicrosoftLicenseUserList] = React.useState([] as any[])
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  let mainBody = {
    accountId: String(id),
    license_name: microsoftLicenseuserListValue?.microsoftLicenseName,
    token: Token!,
  } as AccountDetailsLicenseModelReq

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Fast Name',
      sortable: true,
      hideable: true,
      width: 180,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.userID}`} variant='subtitle2' underline="none">
            <b> {params.row.firstName}</b></Link>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: true,
      width: 180,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.userID}`} variant='subtitle2' underline="none">
            <b> {params.row.lastName}</b></Link>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: false,
      minWidth: 180,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.userID}`} variant='subtitle2' underline="none">
            <b> {params.row.email}</b></Link>
        )
      },
    },
  ]
  React.useEffect(() => {
    setDialogState(msLicenseDialogEnable)
  }, [msLicenseDialogEnable])

  React.useEffect(() => {
    dispatch(fetchMicrosoftAccountDetailsLicenseUserList(mainBody))
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    setMicrosoftLicenseUserList(
      checkReturnValue(microsoftLicenseuserListValue?.accountLicense?.results) !== 'Not Set'
        ? microsoftLicenseuserListValue?.accountLicense?.results.map((item: MsLicenseResult) => ({
          id: item.id,
          userID: item?.account_user?.user?.id,
          firstName:
            checkReturnValue(item?.account_user?.user?.first_name) !== 'Not Set'
              ? item?.account_user?.user?.first_name
              : "Not Set",
          lastName:
            checkReturnValue(item?.account_user?.user?.last_name) !== 'Not Set' ? item?.account_user?.user?.last_name : "Not Set",
          email: checkReturnValue(item?.account_user?.user?.email) !== 'Not Set' ? item?.account_user?.user?.email : "Not Set",
        }))
        : ([] as any[]),
    )
  }, [microsoftLicenseuserListValue])
  return (
    <Dialog open={dialogState} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
          <Grid item>
            License Usage Details &nbsp;&nbsp;&nbsp;
            <Chip
              label={
                microsoftLicenseuserListValue.accountLicense.count !== undefined
                  ? microsoftLicenseuserListValue.accountLicense.count
                  : 0
              }
              sx={{ fontWeight: 'bold' }}
            />
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: 'calc(100vh - 250px)' }}>
          {microsoftLicenseuserListValue?.loading ?(
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) :(
            <DataGridPremium
              columns={columns}
              rows={microsoftLicenseUserList}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              hideFooter
              disableRowGrouping
              disableAggregation
              disableRowSelectionOnClick
              localeText={{ noRowsLabel: 'No Details are available' }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
