import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import {
  UpdateCustomerDetailsIdBody,
  UpdateCustomerDetailsIdResponse,
} from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdModel'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: UpdateCustomerDetailsIdResponse
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateCustomerDetailsIdResponse,
  succssMessage: '',
  error: '',
}

export const updateCustomerDetailsId = createAsyncThunk<
  UpdateCustomerDetailsIdResponse,
  UpdateCustomerDetailsIdBody
>(
  'updateCustomerDetailsId/updateCustomerDetailsId',
  async (payload: UpdateCustomerDetailsIdBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + `/api/customers/${payload.id}`, payload.name, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error.response.data.message || error.response.data[0].detail || 'Something went wrong',
        ),
      )
  },
)

const updateCustomerDetailsIdSlice = createSlice({
  name: 'updateCustomerDetailsId',
  initialState,
  reducers: {
    updateCustomerDetailsClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updateCustomerDetailsClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as UpdateCustomerDetailsIdResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomerDetailsId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateCustomerDetailsId.fulfilled,
      (state, action: PayloadAction<UpdateCustomerDetailsIdResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
          action?.payload?.succssMessage !== undefined
            ? action?.payload?.succssMessage !== ''
              ? action?.payload?.succssMessage
              : 'Customer name updated successfully'
            : 'Customer name updated successfully'
        state.error = ''
      },
    )
    builder.addCase(updateCustomerDetailsId.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateCustomerDetailsIdResponse
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { updateCustomerDetailsClearMsg, updateCustomerDetailsClearRes } =
  updateCustomerDetailsIdSlice.actions
export default updateCustomerDetailsIdSlice.reducer
