import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import ActionItems from './QuickActions/ActionItems'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import {
  updateSubscriptionListClear,
  updateSubscriptionListItemRenewalDate,
} from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
import SelectedActionItems from './QuickActions/SelectedActionItems'
import {
  EditSubscriptionBody,
  MainBody,
} from 'reduxStore/subcription/editSubscription/editSubscriptionModel'
import {
  editSubscriptionApi,
  editSubscriptionClearRes,
} from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'

export default function DataGridTableHeader() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  const selectSubscriptionForSetDeptRes = useAppSelector(
    (state) => state.selectSubscriptionForSetDeptRes,
  )
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  const [selectedItem, setSelectedItems] = useState([] as any[])

  useEffect(() => {
    setSelectedItems(selectSubscriptionForSetDeptRes?.subscriptionLists)
    if (selectSubscriptionForSetDeptRes?.updateType === 3) {
      dispatch(updateSubscriptionListItemRenewalDate(dayjs(selectedDate!).format('YYYY-MM-DD')))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSubscriptionForSetDeptRes])

  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(new Date()))

  const [list, setList] = useState([] as any[])
  useEffect(() => {
    if (selectSubscriptionForSetDeptRes?.subscriptionLists.length > 0) {
      setList(selectSubscriptionForSetDeptRes?.subscriptionLists)
    } else setList([] as any[])
  }, [selectSubscriptionForSetDeptRes])

  const updateSelectedSubscriptions = async () => {
    if (list.length > 0) {
      list.forEach(async (item, index) => {
        let editApiCallBody = {} as EditSubscriptionBody
        editApiCallBody = {
          description: item.description,
          monthly_projected_cost:
            item.monthly_projected_cost !== ''
              ? Number(item.monthly_projected_cost).toFixed(2)
              : Number(item.monthly_projected_cost).toFixed(2),
        } as EditSubscriptionBody
        // set new Department
        if (selectSubscriptionForSetDeptRes?.updateType === 2) {
          Object.assign(editApiCallBody, {
            department_id: selectSubscriptionForSetDeptRes?.value,
          })
        }
        // set new Status
        if (selectSubscriptionForSetDeptRes?.updateType === 4) {
          Object.assign(editApiCallBody, { status_id: selectSubscriptionForSetDeptRes?.value })
        }
        // set new Owner
        if (selectSubscriptionForSetDeptRes?.updateType === 1) {
          Object.assign(editApiCallBody, { owner_id: selectSubscriptionForSetDeptRes?.value })
        }
        // set new Renewal Date
        if (selectSubscriptionForSetDeptRes?.updateType === 3) {
          Object.assign(editApiCallBody, {
            renewal_date: dayjs(selectSubscriptionForSetDeptRes?.renewalDate).format('YYYY-MM-DD'),
          })
        }
        let mainBody = {
          id: item.accountId,
          token: Token!,
          body: editApiCallBody,
        } as MainBody
        if (index === list.length - 1) {
          await dispatch(editSubscriptionApi(mainBody))
          await dispatch(updateSubscriptionListClear())
          await dispatch(fetchSubscription(Token!))
          await dispatch(editSubscriptionClearRes())
        } else {
          await dispatch(editSubscriptionApi(mainBody))
        }
      })
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        px={1}
      >
        {selectedItem.length > 0 ? (
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography>
              <b>{selectedItem.length + ' '}</b> {selectedItem.length > 1 ? 'items' : 'item'}{' '}
              {selectedItem.length > 1 ? 'are' : 'is'} selected
            </Typography>
          </Grid>
        ) : null}
        {selectedItem.length > 0 ? (
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {location.pathname === '/dashboard' ? null : (
                <Grid
                  item
                  xs={
                    location.pathname === '/subscriptions' ||
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 12
                      : 12
                  }
                  sm={
                    location.pathname === '/subscriptions' ||
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  md={
                    location.pathname === '/subscriptions' ||
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  lg={
                    location.pathname === '/subscriptions' ||
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  xl={
                    location.pathname === '/subscriptions' ||
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                >
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <ActionItems />
              </Grid>

              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                {selectSubscriptionForSetDeptRes?.updateType === 3 ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Renewal Date"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue)
                        dispatch(
                          updateSubscriptionListItemRenewalDate(
                            dayjs(newValue).format('YYYY-MM-DD'),
                          ),
                        )
                      }}
                      slotProps={{ textField: { variant: 'standard' } }}
                      sx={{ width: '100%', pb: 1 }}
                    />
                  </LocalizationProvider>
                ) : (
                  <SelectedActionItems />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  disabled={
                    editSubscriptionValue?.loading ||
                    (String(selectSubscriptionForSetDeptRes?.value) === '' &&
                      (selectSubscriptionForSetDeptRes?.renewalDate === '' ||
                        selectSubscriptionForSetDeptRes?.renewalDate === null))
                  }
                  onClick={() => updateSelectedSubscriptions()}
                >
                  {!editSubscriptionValue?.loading ? (
                    'Apply Changes'
                  ) : (
                    <CircularProgress color="inherit" size={30} />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {selectedItem.length > 0 ? null : (
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid
                item
                xs={
                  location.pathname === '/subscriptions' ||
                  location.pathname === '/optimizations' ||
                  location.pathname === '/breaches' ||
                  location.pathname.includes('/breachdetail')
                    ? 12
                    : 12
                }
                sm={
                  location.pathname === '/subscriptions' ||
                  location.pathname === '/optimizations' ||
                  location.pathname === '/breaches' ||
                  location.pathname.includes('/breachdetail')
                    ? 6
                    : 10
                }
                md={
                  location.pathname === '/subscriptions' ||
                  location.pathname === '/optimizations' ||
                  location.pathname === '/breaches' ||
                  location.pathname.includes('/breachdetail')
                    ? 6
                    : 10
                }
                lg={
                  location.pathname === '/subscriptions' ||
                  location.pathname === '/optimizations' ||
                  location.pathname === '/breaches' ||
                  location.pathname.includes('/breachdetail')
                    ? 6
                    : 10
                }
                xl={
                  location.pathname === '/subscriptions' ||
                  location.pathname === '/optimizations' ||
                  location.pathname === '/breaches' ||
                  location.pathname.includes('/breachdetail')
                    ? 6
                    : 10
                }
              >
                <MUIDDCustomToolbar />
              </Grid>
              {location.pathname === '/dashboard' ||
              location.pathname === '/subscriptions' ? null : (
                <Grid
                  item
                  xs={
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 12
                      : 12
                  }
                  sm={
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  md={
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  lg={
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                  xl={
                    location.pathname === '/optimizations' ||
                    location.pathname === '/breaches' ||
                    location.pathname.includes('/breachdetail')
                      ? 6
                      : 2
                  }
                >
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export function ApplogieDGToolbar(props: any) {
  const location = useLocation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box>
      <Portal container={() => document.getElementById('filter-panel')!}>
        <GridToolbarQuickFilter
          style={{
            minWidth:
              location.pathname === '/subscriptions' ||
              location.pathname === '/optimizations' ||
              location.pathname === '/breaches' ||
              location.pathname.includes('/breachdetail')
                ? fullScreen
                  ? '200px'
                  : '400px'
                : '128px',
          }}
        />
      </Portal>
      <DataGridTableHeader {...props} />
    </Box>
  )
}
