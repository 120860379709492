import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { SuspiciousActivityModel, AccountDetailsModelReq } from './SuspiciousActivityModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountDetails: SuspiciousActivityModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  accountDetails: {} as SuspiciousActivityModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchSuspiciousActivityListById = createAsyncThunk<SuspiciousActivityModel, AccountDetailsModelReq>(
  'suspiciousActivityList/fetchSuspiciousActivityListById',
  (reqParam: AccountDetailsModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/suspiciousactivity?account_id=${reqParam.accountId}`, { headers })
      .then((response) => response.data)
  },
)

const SuspiciousActivityListSlice = createSlice({
  name: 'suspiciousActivityList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSuspiciousActivityListById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchSuspiciousActivityListById.fulfilled,
      (state, action: PayloadAction<SuspiciousActivityModel>) => {
        state.loading = false
        state.accountDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchSuspiciousActivityListById.rejected, (state, action) => {
      state.loading = false
      state.accountDetails = {} as SuspiciousActivityModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default SuspiciousActivityListSlice.reducer
