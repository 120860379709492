import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { MicrosoftUserLicenseModel, AccountDetailsLicenseModelReq } from './MicrosoftUserLicenseModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountLicense: MicrosoftUserLicenseModel
  error: string
  microsoftLicenseName: string
  toggleDialogState: boolean

}
const initialState: InitialState = {
  loading: false,
  accountLicense: {} as MicrosoftUserLicenseModel,
  error: '',
  microsoftLicenseName: '',
  toggleDialogState: false
}

// Generates pending, fulfilled and rejected action types
export const fetchMicrosoftAccountDetailsLicenseUserList = createAsyncThunk<MicrosoftUserLicenseModel, AccountDetailsLicenseModelReq>(
  'microsoftUserLicenseDetails/fetchMicrosoftAccountDetailsLicenseUserList',
  (reqParam: AccountDetailsLicenseModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/msaccountusers?account=${reqParam.accountId}&license_name=${reqParam.license_name}`, { headers })
      .then((response) => response.data)
  },
)

const MicrosoftUserLicenseSlice = createSlice({
  name: 'microsoftUserLicenseDetails',
  initialState,
  reducers: {
    microsoftUserLicenseClearMsg: (state) => {
      state.error = ''
      state.microsoftLicenseName = ''
    },
    microsoftUserLicenseClearRes: (state) => {
      state.error = ''
      state.accountLicense = {} as MicrosoftUserLicenseModel
      state.microsoftLicenseName = ''
    },
    updateMicrosoftLicenseUser: (state, action) => {
      state.microsoftLicenseName = action.payload
    },
    toggleMicrosoftLicenseUserDialogState: (state, action) => {
      state.toggleDialogState = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMicrosoftAccountDetailsLicenseUserList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchMicrosoftAccountDetailsLicenseUserList.fulfilled,
      (state, action: PayloadAction<MicrosoftUserLicenseModel>) => {
        state.loading = false
        state.accountLicense = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchMicrosoftAccountDetailsLicenseUserList.rejected, (state, action) => {
      state.loading = false
      state.accountLicense = {} as MicrosoftUserLicenseModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { microsoftUserLicenseClearMsg, microsoftUserLicenseClearRes, updateMicrosoftLicenseUser, toggleMicrosoftLicenseUserDialogState } =
  MicrosoftUserLicenseSlice.actions
export default MicrosoftUserLicenseSlice.reducer
