import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import { Box, Link } from '@mui/material'
import { renderApplogieDate } from 'components/Utility'

export function SuspiciousActivityListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.id}`} variant='subtitle2' underline="none"> 
          <b> {params.row.firstName}</b></Link>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.id}`} variant='subtitle2' underline="none"> 
          <b> {params.row.lastName}</b></Link>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Created',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.created !== null
            ? renderApplogieDate(params.row.created!)
            : null}
        </React.Fragment>
      ),
    },
    {
      field: 'hide',
      headerName: 'Hidden',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <Box>{params.row.hide ? 'True' : 'False'}</Box>
        )
      },
    },
  ]
  return columns
}
