import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle'
import { renderApplogieDate } from 'components/Utility'
import { Box } from '@mui/material'

export function BreachesTableColumn() {
  const location = useLocation()
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Vendor Name',
      sortable: true,
      hideable: true,
      minWidth: 260,
      align: 'left',
    },

    {
      field: 'date',
      headerName: 'Breach Date',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          <Box>{renderApplogieDate(params.row.date) !== null && renderApplogieDate(params.row.date)}</Box>
        </React.Fragment>
      ),
    },

    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      renderCell: (params) =>
        location.pathname === '/dashboard' ? null : (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
