import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { LogOutResponseBody, LogOutResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: LogOutResponse
  successMsg: string
  errorMsg: string
  hasResetPassword: boolean
}
const initialState: InitialState = {
  loading: false,
  response: {} as LogOutResponse,
  successMsg: '',
  errorMsg: '',
  hasResetPassword: false,
}
let resetPwd = false
export const logOut = createAsyncThunk<LogOutResponse, LogOutResponseBody>(
  'logOut/logOut',
  async (payload: LogOutResponseBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    resetPwd = payload?.hasResetPassword !== undefined ? payload?.hasResetPassword : false
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + '/logout', { token: payload.token }, { headers })
      .then((response) => {
        localStorage.clear()
        return response?.data?.message !== undefined
          ? response?.data?.message !== null
            ? response?.data !== ''
              ? response?.data
              : 'You have been logout successfully'
            : 'You have been logout successfully'
          : 'You have been logout successfully'
      })
      .catch((error) => {
        localStorage.clear()
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'You have been logout forcefully'
            : 'You have been logout forcefully',
        )
        window.location.reload()
      })
  },
)

const logOutSlice = createSlice({
  name: 'logOut',
  initialState,
  reducers: {
    updateLogOutClearMsg: (state) => {
      state.successMsg = ''
      state.errorMsg = ''
      state.hasResetPassword = false
    },
    updateLogOutClearRes: (state) => {
      state.successMsg = ''
      state.errorMsg = ''
      state.response = {} as LogOutResponse
      state.hasResetPassword = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.pending, (state) => {
      state.loading = true
    })
    builder.addCase(logOut.fulfilled, (state, action: PayloadAction<LogOutResponse>) => {
      state.loading = false
      state.response = action.payload
      state.successMsg = action.payload.message
      state.errorMsg = ''
      state.hasResetPassword = resetPwd
    })
    builder.addCase(logOut.rejected, (state, action) => {
      state.loading = false
      state.response = {} as LogOutResponse
      state.successMsg = ''
      state.errorMsg = String(action.payload!)
      state.hasResetPassword = resetPwd
    })
  },
})
export const { updateLogOutClearMsg, updateLogOutClearRes } = logOutSlice.actions
export default logOutSlice.reducer
