import * as React from 'react'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../reduxStore/hooks'
import { SearchActiveUser } from '../SearchActiveUser'
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Chip,
  TextField,
  Autocomplete,
  InputAdornment,
  CardHeader,
  AlertColor,
  useMediaQuery,
  useTheme,
  Pagination,
  CardActions,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import PeopleIcon from '@mui/icons-material/People'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { fetchActiveuser } from 'reduxStore/users/Active/activeuserSlice'
import { ActiveUsersDetailsReq, Result } from 'reduxStore/users/Active/Model'
import { toggleActiveByUserIdClearRes } from 'reduxStore/users/toggleActive/toggleActiveSlice'
import { fetchUserListByOrgTag } from 'reduxStore/users/userListByOrgTag/userListByOrgTagSlice'
import { UserListByTagSearchReq } from 'reduxStore/users/userListByOrgTag/Model'
import { DataGridPremium, GridRowParams, DataGridPremiumProps } from '@mui/x-data-grid-premium'
import { ActiveAccountTableColumn } from './ActiveAccountTableColumn'
import ShowAccountDetails from './ShowAccountDetails'
import InactiveUserDialog from './InactiveUserDialog'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { searchUserClearRes } from 'reduxStore/users/searchUser/searchUserSlice'
import { checkReturnValue } from 'components/Utility'
type Props = {
  tabIndex?: number | any
}
export interface TagList {
  name: string
  id: number
}

export const ActiveUser = ({ tabIndex }: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  let { tagName } = useParams()
  const pageCount = useAppSelector(
    (state) => state.activeUsers.activeusers.numberOfPages,
  );
  const activeUserVal = useAppSelector((state) => state.activeUsers)
  const searchUserListValue = useAppSelector((state) => state.userSearch)
  const toggleActiveValue = useAppSelector((state) => state.getToggleActivateByUserId)
  const getUserTagList = useAppSelector((state) => state.getUserTagList)
  const searchTagListValue = useAppSelector((state) => state.userListByOrgTag)
  const [userStatus, setUserStatus] = React.useState(0)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    if (tabIndex !== undefined) {
      setUserStatus(tabIndex)
    }
  }, [tabIndex])

  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState("");
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    let activeUserDetailsParam = {
      pageCount: String(value),
      length: String(50),
      token: Token!,
    } as ActiveUsersDetailsReq
    dispatch(fetchActiveuser(activeUserDetailsParam))
  };

  const updateToggleCompanyUserByIDDialogState = (id: string) => {
    setSelectedItem(String(id))
    setShowToggleActiveUserByIDDialog(true)
  }
  let [searchParams, setSearchParams] = useSearchParams()
  const [showToggleActiveUserByIDDialog, setShowToggleActiveUserByIDDialog] = React.useState(false)

  // get data from ToggleCompanyUserByID Dialog (child) component
  const updateToggleActiveUserByIDDialogValFromChild = (passedVal: boolean) => {
    setShowToggleActiveUserByIDDialog(passedVal)
  }

  const [userList, setUserList] = React.useState([] as any[])
  // get user list table value...
  const [modifiedUserList, setModifiedUserList] = React.useState([] as any[])

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  function DetailPanelContent({ row }: { row: any }) {
    return <ShowAccountDetails item={row} />
  }
  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if ('response' in searchUserListValue) {
      if (searchUserListValue.response !== null) {
        setUserList(searchUserListValue.response)
      }
    }
  }, [searchUserListValue])

  useEffect(() => {
    if (activeUserVal.activeusers?.results !== null) {
      if (activeUserVal.activeusers !== undefined) {
        setTotalCount(String(activeUserVal.activeusers?.count))
        if (activeUserVal.activeusers.results!) {
          setUserList(activeUserVal.activeusers?.results)
        }
      }
    }
  }, [activeUserVal])

  useEffect(() => {
    if ('userListByOrgTag' in searchTagListValue) {
      if (searchTagListValue.userListByOrgTag !== undefined) {
        if (searchTagListValue.userListByOrgTag !== null) {
          setUserList(searchTagListValue.userListByOrgTag)
          setSearchParams(searchParams)
          setTotalCount(String(searchTagListValue?.userListByOrgTag?.length))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTagListValue])

  const [selectedItem, setSelectedItem] = React.useState('')

  function selectActiveUsersItem() {
    let activeUserDetailsParam = {
      pageCount: String(1),
      length: String(50),
      token: Token!,
    } as ActiveUsersDetailsReq
    dispatch(fetchActiveuser(activeUserDetailsParam))
  }
  const [, setUserItemExpand] = React.useState<boolean[]>([])
  const [tagList, setTagList] = React.useState([] as any[])

  const [selectedOrgTag, setSelectedOrgTag] = React.useState<TagList | null>(null)
  const filterTagSearch = (owner: any, _e: any) => {
    setSelectedOrgTag(owner)
    if (owner === null) {
      searchParams.delete('searchbytag')
      setSearchParams(searchParams)
    }
  }
  useEffect(() => {
    if (tagName !== undefined) {
      setSelectedOrgTag(tagList.find((o) => o.name === tagName))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList, tagName])
  useEffect(() => {
    if (selectedOrgTag !== undefined) {
      if (selectedOrgTag !== null) {
        if ('id' in selectedOrgTag) {
          searchUsrByTag(selectedOrgTag)
          searchParams.set('searchbytag', selectedOrgTag?.name)
          setSearchParams(searchParams)
        }
      }
      else {
        let activeUserDetailsParam = {
          pageCount: String(1),
          length: String(50),
          token: Token!,
        } as ActiveUsersDetailsReq
        dispatch(fetchActiveuser(activeUserDetailsParam))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgTag])
  // Search user by Org Tag
  const searchUsrByTag = (newValue: any) => {
    let checkUerActivate = userStatus === 0 ? 'True' : 'False'
    if (newValue !== null) {
      if (newValue !== '') {
        let body = {
          name: String(newValue.name),
          status: checkUerActivate,
          token: Token!,
        } as UserListByTagSearchReq
        dispatch(fetchUserListByOrgTag(body))
      }
    }
  }
  useEffect(() => {
    if ('tagList' in getUserTagList) {
      if (getUserTagList !== undefined) {
        let tagname = ''
        let searchKey: any[] = []
        if (getUserTagList.tagList !== undefined) {
          if (getUserTagList.tagList !== null) {
            if ('tags' in getUserTagList.tagList) {
              if (getUserTagList.tagList.tags !== undefined) {
                for (var k = 0; k < getUserTagList.tagList.tags.length; k++) {
                  tagname = getUserTagList.tagList.tags[k]
                  searchKey.push({ id: k + 1, name: tagname })
                }
                setTagList(searchKey)
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserTagList])

  useEffect(() => {
    if ('results' in activeUserVal.activeusers) {
      if (activeUserVal.activeusers.results.length > 0) {
        setUserItemExpand([...Array(activeUserVal.activeusers.results.length)].map(() => false))
      }
    }
  }, [activeUserVal])
  const [apiResMsg, setAPIresMsg] = React.useState('')
  const [msgType, setMsgType] = React.useState<AlertColor>('success')
  const [searchApiResMsg, setSearchAPIresMsg] = React.useState('')

  React.useEffect(() => {
    if (toggleActiveValue?.succssMessage !== '') {
      setMsgType('success')
      setAPIresMsg(toggleActiveValue?.succssMessage)
      let activeUserDetailsParam = {
        pageCount: String(1),
        length: String(50),
        token: Token!,
      } as ActiveUsersDetailsReq
      dispatch(fetchActiveuser(activeUserDetailsParam))
    }
    if (toggleActiveValue?.error !== '') {
      setMsgType('error')
      setAPIresMsg(toggleActiveValue?.error)
    }
    if (toggleActiveValue?.deActivatedUsers !== '') {
      updateToggleCompanyUserByIDDialogState(toggleActiveValue?.deActivatedUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleActiveValue])

  React.useEffect(() => {
    if (searchUserListValue?.succssMessage !== '') {
      setMsgType('success')
      setSearchAPIresMsg(searchUserListValue?.succssMessage)
      setTotalCount(String(searchUserListValue?.response?.length))
    }
    if (searchUserListValue?.error !== '') {
      setMsgType('error')
      setSearchAPIresMsg(searchUserListValue?.error)
    }
  }, [searchUserListValue])

  const closeActiveUserStatusChangeSnackbar = () => {
    dispatch(toggleActiveByUserIdClearRes())
    setAPIresMsg('')
    setMsgType('success')
  }

  const closeSearchUserSnackbar = () => {
    dispatch(searchUserClearRes())
    setSearchAPIresMsg('')
    setMsgType('success')
  }

  useEffect(() => {

    if ('results' in searchUserListValue.response) {
      if (searchUserListValue.response.length > 0) {
        setUserItemExpand([...Array(searchUserListValue.response.length)].map(() => false))
      }
    }
  }, [searchUserListValue])

  useEffect(() => {
    if (userList.length > 0) {
      let userObj = userList.map((element: Result) => {
        return {
          id: element.id,
          firstName:
            element?.first_name! !== undefined && element?.first_name !== ''
              ? element.first_name
              : 'Not Set',
          lastName:
            element?.last_name! !== undefined && element?.last_name !== ''
              ? element.last_name
              : 'Not Set',
          email: element?.email! !== undefined && element?.email !== '' ? element.email : 'Not Set',
          status: element?.active! !== undefined ? element.active : false,
          accountName:
            element?.account_set !== undefined ? element?.account_set.length > 0 ? element.account_set.map(item => ({
              label: item.account.vendor.name,
              value: item.account.id
            })) : "Not Set" : "Not Set",
          department:
            checkReturnValue(element?.department?.name) !== 'Not Set'
              ? element?.department?.name
              : null,
          account: element?.account_set!,
        }
      })
      setModifiedUserList(userObj)
    }
  }, [userList])

  return (
    <Box>
      <Card>
        <CardHeader
          avatar={<PeopleIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Active Users
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip label={totalCount}></Chip>
                  </Grid>
                  <Grid item>
                    <RefreshIcon
                      sx={{ height: '1.4em', '&:hover': { cursor: 'pointer' } }}
                      onClick={() => selectActiveUsersItem()}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <SearchActiveUser tabIndex={userStatus} />
              </Grid>
              <Grid item>
                <Autocomplete
                  id="dept-states"
                  options={tagList}
                  value={selectedOrgTag}
                  onChange={(event: any, newValue: any) => {
                    filterTagSearch(newValue, event)
                  }}
                  getOptionLabel={(option: any) => option.name}
                  isOptionEqualToValue={(option: any, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Select Tag Name"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalOfferIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ minWidth: fullScreen ? '128px' : '200px' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {activeUserVal.loading || searchTagListValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: 'calc(100vh - 261px)' }}>
              <DataGridPremium
                sx={{
                  '@media print': {
                    '.MuiDataGrid-main': {
                      width: 'fit-content',
                      fontSize: '20px',
                      height: 'fit-content',
                      overflow: 'visible',
                    },
                    marginBottom: 100,
                  },
                  '& .MuiDataGrid-footerContainer': {
                    height: '0px',
                    minHeight: '0px',
                  },
                }}
                columns={ActiveAccountTableColumn()}
                rows={modifiedUserList}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'firstName', sort: 'asc' }],
                  },
                }}
                slots={{
                  toolbar: MUIDDCustomToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooterRowCount
                hideFooterSelectedRowCount
                disableRowGrouping
                localeText={{ noRowsLabel: 'No Users are available' }}
              />
            </Box>
          )}
        </CardContent>
        {showToggleActiveUserByIDDialog ? (
          <InactiveUserDialog
            itemId={selectedItem}
            toggleActiveUserByIDDialogEnable={showToggleActiveUserByIDDialog}
            toggleActiveUserByIDDialogUpdateState={updateToggleActiveUserByIDDialogValFromChild}
          />
        ) : null}

        {toggleActiveValue.toggleActivateByUserID !== undefined ? (
          <ApplogieSnackbar
            msg={apiResMsg}
            type={msgType}
            alertClose={closeActiveUserStatusChangeSnackbar}
          />
        ) : null}
        {searchUserListValue.response !== undefined ? (
          <ApplogieSnackbar
            msg={searchApiResMsg}
            type={msgType}
            alertClose={closeSearchUserSnackbar}
          />
        ) : null}
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {!fullScreen && (
              <Grid item>
                <Typography>Page: {page}</Typography>
              </Grid>
            )}
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageCount)}
                page={page}
                onChange={handleChange}
                sx={{ flex: "end" }}
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  )
}
