import * as React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks'
import {
  CardContent,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import { fetchOptimization } from 'reduxStore/optimizations/optimizationsList/optimizationSlice'
import InfoIcon from '@mui/icons-material/Info'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { ModifiedOptimizationsList } from 'pages/Optimizations/Model'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import { renderApplogieDate } from 'components/Utility'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F8FA',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: '1px solid #dfe8ef',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(21, 171, 209, 0.87)',
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// // with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof ModifiedOptimizationsList
  label: string | any
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'vendorName',
    numeric: false,
    disablePadding: true,
    label: (
      <CustomTooltip title="The name of the vendor for the optimization" placement="bottom" arrow>
        <Typography>Vendor Name</Typography>
      </CustomTooltip>
    ),
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: (
      <CustomTooltip
        title="Description of what this optimization is about Message"
        placement="bottom"
        arrow
      >
        <Typography>Message</Typography>
      </CustomTooltip>
    ),
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: (
      <CustomTooltip
        title="The date this optimization was created Create Date"
        placement="bottom"
        arrow
      >
        <Typography>Create Date</Typography>
      </CustomTooltip>
    ),
  },
  {
    id: 'overage',
    numeric: false,
    disablePadding: false,
    label: (
      <CustomTooltip
        title="The overage amount (calculated per 12 months) Overage"
        placement="bottom"
        arrow
      >
        <Typography>Overage</Typography>
      </CustomTooltip>
    ),
  },
  {
    id: 'potentialSavings',
    numeric: false,
    disablePadding: false,
    label: (
      <CustomTooltip
        title="The overage amount (calculated per 12 months) Potential Savings"
        placement="bottom"
        arrow
      >
        <Typography>Potential Savings</Typography>
      </CustomTooltip>
    ),
  },
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ModifiedOptimizationsList,
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

export default function AccDetailsOptimizations() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const optimizationVal = useAppSelector((state) => state.optimization)

  let navigate = useNavigate()

  const showId = (id: number) => {
    navigate(`/detail/${id}`)
  }

  useEffect(() => {
    dispatch(fetchOptimization(Token!))
    // eslint-disable-next-line no-lone-blocks
    dispatch(fetchAlerts(Token!))
  }, [dispatch, Token])

  const [optimizationsList, setOptimizationsList] = React.useState([] as any[])
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (optimizationVal?.optimizations?.results! !== undefined) {
        let optimizationsObj = optimizationVal?.optimizations?.results!.map((element) => {
          return {
            id: element.id,
            vendorName:
              element.account?.vendor?.name! !== undefined
                ? element.account?.vendor?.name!
                : 'Not Set',
            vendorID: element.account?.id! !== undefined ? element.account?.id! : 0,
            message: element?.message! !== undefined ? element?.message : 'Not Set',
            createDate: element?.from_date! !== undefined ? element?.from_date! : 'Not Set',
            overage: element?.overage !== undefined ? element?.overage : 'Not Set',
            potentialSavings:
              element?.potential_savings !== undefined ? element?.potential_savings : 'Not Set',
          }
        })
        setOptimizationsList(optimizationsObj)
      }
    }
  }, [optimizationVal])

  // Sortable Table
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof ModifiedOptimizationsList>('vendorName')
  const [selected, setSelected] = React.useState<readonly string[]>([])

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const createSortHandler =
      (property: keyof ModifiedOptimizationsList) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
      }

    return (
      <TableHead>
        <StyledTableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    )
  }
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof ModifiedOptimizationsList,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleClick = (_event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(String(id))
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, String(id))
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }
  function Row(props: { row: ModifiedOptimizationsList }) {
    const { row } = props
    return (
      <React.Fragment>
        <TableRow
          hover
          sx={{ '& > *': { borderBottom: 'unset' } }}
          key={row.id}
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
        >
          <TableCell component="th" scope="row">
            <Typography
              sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
              onClick={() => showId(row.vendorID!)}
            >
              <b>{row.vendorName}</b>
            </Typography>
          </TableCell>
          <TableCell align="left">{row.message}</TableCell>
          <TableCell align="left">
            {row.createDate !== undefined
              ? renderApplogieDate(row.createDate!)
              : '---'}
          </TableCell>
          <TableCell align="left">{row.overage !== null ? numberWithDecimalCommas(Number(row?.overage)) : '$0.00'}</TableCell>
          <TableCell align="left">
            {row.potentialSavings !== null ? numberWithDecimalCommas(Number(row?.potentialSavings)) : '$0.00'}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <CardContent>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="flex-start"
          pb={2}
        >
          <Stack direction={'row'} spacing={1} sx={{ marginTop: '1em' }}>
            <InfoIcon sx={{ height: '1.2em' }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Optimizations
            </Typography>
            <Chip
              label={
                'count' in optimizationVal.optimizations
                  ? optimizationVal.optimizations.count
                  : null
              }
            />
          </Stack>
        </Stack>
        {optimizationVal.loading === true ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" size={20} thickness={4} />
          </Grid>
        ) : (
          <TableContainer>
            <Table
              stickyHeader
              aria-label="simple table"
              sx={{
                border: '1px solid #dfe8ef',
                '& > *': { borderBottom: 'unset' },
                paddingLeft: '5px',
              }}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={optimizationsList.length}
              />
              <TableBody>
                {optimizationsList !== undefined
                  ? stableSort(optimizationsList as any, getComparator(order, orderBy)).map(
                      (row: any) => <Row key={row.id} row={row} />,
                    )
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Box>
  )
}
