import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PersonIcon from '@mui/icons-material/Person'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import UpdatePassword from './UpdatePassword'
import { User } from 'reduxStore/login/Model'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { updatePasswordClearRes } from 'reduxStore/updatePassword/updatePasswordSlice'
import { LogOutResponseBody } from 'reduxStore/logOut/Model'
import { logOut } from 'reduxStore/logOut/logOutSlice'

export default function UserDetails() {
  const dispatch = useAppDispatch()
  var userDetails = JSON.parse(localStorage.getItem('user')!) as User
  const token = localStorage.getItem('token')
  const accountDetailsById = useAppSelector((state) => state.customerDetails)
  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }
  // get data from child component
  const updateDialogValFromChild = (passedVal: boolean) => {
    setShowDialog(passedVal)
  }
  const [showUpdatePasswordMsgType, setShowUpdatePasswordMsgType] =
    React.useState<AlertColor>('success')
  const [showUpdatePasswordMsg, setShowUpdatePasswordMsg] = React.useState('')

  const updatePassVal = useAppSelector((state) => state.updatePassword)
  React.useEffect(() => {
    if (updatePassVal?.succssMessage !== '') {
      setShowUpdatePasswordMsgType('success')
      setShowUpdatePasswordMsg(updatePassVal?.succssMessage)
    }
    if (updatePassVal?.error !== '') {
      setShowUpdatePasswordMsgType('error')
      setShowUpdatePasswordMsg(updatePassVal?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePassVal])

  const closeUpdatePasswordSnackbar = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updatePasswordClearRes())
      setShowUpdatePasswordMsg('')
      showUpdatePasswordMsgType === 'success' &&
        dispatch(
          logOut({
            token: token,
            hasResetPassword: true,
          } as LogOutResponseBody),
        )
    }
  }
  return (
    <Box>
      <Card sx={{ minHeight: '270px', maxHeight: '270px' }}>
        <CardHeader
          avatar={<PersonIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack direction={'row'} sx={{ marginTop: '2px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  User Details
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  startIcon={<LockOpenIcon />}
                  onClick={() => updateDialogState()}
                >
                  Update Password
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          {accountDetailsById.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ width: '100%', bgcolor: '#F2F2F2' }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                p={1}
                spacing={1}
              >
                <Grid item md={6}>
                  <Typography variant="body2">User name</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                    {userDetails.username}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2">Email</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                    {userDetails.email}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2">First Name</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                    {userDetails.first_name}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2">Last Name</Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                    {userDetails.last_name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
      {showDialog ? (
        <UpdatePassword dialogEnable={showDialog} updatedDialogState={updateDialogValFromChild} />
      ) : null}
      {showUpdatePasswordMsg && (
        <ApplogieSnackbar
          msg={showUpdatePasswordMsg}
          type={showUpdatePasswordMsgType}
          alertClose={closeUpdatePasswordSnackbar}
        />
      )}
    </Box>
  )
}
