import { AlertColor, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { AlertView } from 'components/AlertView'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { ClearAllAlertsReq } from 'reduxStore/alert/ClearAllAlerts/Model'
import { clearAllAlerts, updateClearAllAlertClearRes } from 'reduxStore/alert/ClearAllAlerts/clearAllAlertsSlice'
import ApplogieSnackbar from './ApplogieSnackbar'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
type Props = {
  alertDialogEnable?: boolean | any
  alertDialogUpdateState?: boolean | any
}

export default function AlertDialog({
  alertDialogEnable,
  alertDialogUpdateState,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const [clearAlertModalActive, setClearAlertModalActive] = React.useState(false)
  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showClearAllAlertMsg, setShowClearAllAlertMsg] = React.useState('')
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  const handleClose = () => {
    setDialogState(false)
    alertDialogUpdateState(false)
  }
  const handleClearAlertClose = () => {
    setClearAlertModalActive(false)
  }
  const closeClearAllAlertMsgSnackbar = () => {
    dispatch(updateClearAllAlertClearRes())
    setShowClearAllAlertMsg('')
  }
  const openCleartAlert = () => {
    setClearAlertModalActive(true)
  }
  const clearAllAlertsApiResponse = useAppSelector((state) => state.clearAllAlerts)
  const alert = useAppSelector((state) => state.alert)

  let ClearAllAlertReqBody = {
    token: Token!,
  } as ClearAllAlertsReq

  const clearAllAlertsApiIntegration = async () => {
    await dispatch(clearAllAlerts(ClearAllAlertReqBody))
  }

  React.useEffect(() => {
    if (clearAllAlertsApiResponse?.successMsg !== '') {
      setAPIMsgType('success')
      setShowClearAllAlertMsg(clearAllAlertsApiResponse?.successMsg)
      handleClearAlertClose()
      dispatch(fetchAlerts(Token!))
    }
    if (clearAllAlertsApiResponse?.error !== '') {
      if (clearAllAlertsApiResponse?.error) {
        setAPIMsgType('error')
        setShowClearAllAlertMsg(clearAllAlertsApiResponse?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAllAlertsApiResponse])

  React.useEffect(() => {
    setDialogState(alertDialogEnable)
  }, [alertDialogEnable])

  const clearAlertModalBody = (
    <Dialog open={clearAlertModalActive} onClose={handleClearAlertClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Are you sure you want to clear all alerts?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClearAlertClose}
          autoFocus
          disabled={clearAllAlertsApiResponse?.loading}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => clearAllAlertsApiIntegration()}
          disabled={clearAllAlertsApiResponse?.loading}
        >
          {clearAllAlertsApiResponse.loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            <Typography>Yes</Typography>
          )}

        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Box>
      <Dialog open={dialogState} onClose={handleClose}>
        <DialogContent dividers>
          <Grid item xs={6} md={4}>
            <AlertView />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              {alert.alerts.length > 0 ? (
                <Button
                  onClick={openCleartAlert}
                  variant="contained"
                >
                  <Typography>Clear</Typography>
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                style={{ backgroundColor: '#DCDCDC' }}
              >
                <Typography sx={{ color: '#6253B5', fontWeight: 'bold' }}>Close</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {clearAlertModalBody}
      <ApplogieSnackbar
        msg={showClearAllAlertMsg}
        type={apiMsgType}
        alertClose={closeClearAllAlertMsgSnackbar}
      />
    </Box>
  )
}
