import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import UpdateLicense from '../UpdateLicense/UpdateLicense'
import DeleteLicense from '../DeleteLicense/DeleteLicense'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { updateUsageByIdClearRes } from 'reduxStore/account/license/usage/updateUsageByID/updateUsageById'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { LicenseListTableColumn } from './LicenseListColumn'
import { LicenseTypeValueList, ModifiedLicenseList } from '../LicenseTypePeriodList'
import {
  Result as LicenseListResult,
  LisenceListReq,
} from '../../../../reduxStore/account/license/licenseListByAccountID/Model'
import { checkReturnValue, renderApplogieDate } from 'components/Utility'
import {
  fetchLicense,
  updateAddEditDialogState,
} from 'reduxStore/account/license/licenseListByAccountID/licenseSlice'
import { useParams } from 'react-router-dom'

export default function LicenseList() {
  const Token = localStorage.getItem('token')
  // Get the userId param from the URL.
  let { id } = useParams()
  const dispatch = useAppDispatch()
  const licenseValue = useAppSelector((state) => state.license)
  const updateUsageByIdRes = useAppSelector((state) => state.updateUsageByIdRes)

  // Add License Dialog
  const [addLicenseSuccessMessage, setAddLicenseSuccessMessage] = React.useState('')
  const addLicenseRes = useAppSelector((state) => state.addLicense)
  React.useEffect(() => {
    if (addLicenseRes?.succssMessage !== '') {
      setAddLicenseSuccessMessage(addLicenseRes?.succssMessage)
    }
  }, [addLicenseRes])

  // Edit License Dialog
  const [successEditMessage, setSuccessEditMessage] = React.useState('')
  const [showAddEditLicenseDialog, setShowAddEditLicenseDialog] = React.useState(false)
  const [item, setItem] = React.useState({} as any)

  const editLicenseDialogUpdatedState = (_passedVal: boolean) => {
    dispatch(updateAddEditDialogState(false))
  }
  const handleEditSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setSuccessEditMessage(message)
  }

  const [deleteLicenseMessage, setDeleteLicenseMessage] = React.useState('')
  const [showDeleteLicenseDialog, setShowDeleteLicenseDialog] = React.useState(false)

  const deleteLicenseByIDRes = useAppSelector((state) => state.deleteLicenseByID)
  // Delete License Dialog
  React.useEffect(() => {
    setShowDeleteLicenseDialog(deleteLicenseByIDRes?.enableDialog)
    if (deleteLicenseByIDRes?.error) {
      setDeleteLicenseMessage(deleteLicenseByIDRes?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLicenseByIDRes])

  const closeUserDetailsSnackbar = () => {
    setAddLicenseSuccessMessage('')
    setSuccessEditMessage('')
    setDeleteLicenseMessage('')
  }

  const [updateUsageByIdMsg, setUpdateUsageByIdMsg] = React.useState('')
  const [updateUsageByIdMsgType, setUpdateUsageByIdMsgType] = React.useState<AlertColor>('success')
  const closeSnackBar = () => {
    dispatch(updateUsageByIdClearRes())
    setUpdateUsageByIdMsg('')
  }

  React.useEffect(() => {
    if (updateUsageByIdRes?.successMessage !== '') {
      setUpdateUsageByIdMsgType('success')
      setUpdateUsageByIdMsg(updateUsageByIdRes?.successMessage)
      dispatch(updateUsageByIdClearRes())
    }
    if (updateUsageByIdRes?.errorMessage !== '') {
      setUpdateUsageByIdMsgType('error')
      setUpdateUsageByIdMsg(updateUsageByIdRes?.errorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUsageByIdRes])

  const [licenseList, setLicenseList] = React.useState([] as ModifiedLicenseList[])
  React.useEffect(() => {
    setShowAddEditLicenseDialog(licenseValue?.enableDialog)
    setItem(licenseValue?.selectedItem)
    if (licenseValue?.licenses?.results !== undefined) {
      let array = licenseValue?.licenses?.results.map((item: LicenseListResult) => {
        return {
          id: 'id' in item ? item.id : 0,
          name: checkReturnValue(item.sku_part_number),
          value:
            numberWithDecimalCommas(Number(item.value)) !== ''
              ? numberWithDecimalCommas(Number(item.value))
              : 'Not Set',
          count: checkReturnValue(item.license_count),
          type: checkReturnValue(item?.licenseType),
          period: checkReturnValue(item.period_name),
          description: checkReturnValue(item.description),
          modifiedDescription:
            checkReturnValue(item.description) !== 'Not Set'
              ? item.description.length > 20
                ? item.description.slice(0, 20) + '...'
                : item.description
              : '',
          startDate:(checkReturnValue(item.license_start_date)),
          endDate:
            item.license_end_date !== null
              ? renderApplogieDate(item.license_end_date!)
              : 'Not Set',
        }
      })
      setLicenseList(array)
    } else setLicenseList([] as ModifiedLicenseList[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseValue, LicenseTypeValueList])

  return (
    <Box>
      <Card sx={{ minHeight: '330px' }}>
        <CardHeader
          avatar={<CreditCardIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Licenses
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        'count' in licenseValue.licenses
                          ? String(licenseValue.licenses.count)
                          : null
                      }
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        dispatch(
                          fetchLicense({
                            id: String(id),
                            token: Token!,
                          } as LisenceListReq),
                        )
                      }
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  style={{ marginTop: '1em', backgroundColor: '#30a289', textTransform: 'none' }}
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(updateAddEditDialogState(true))}
                >
                  <Typography sx={{ color: '#fff', fontSize: '15px' }}>Add License</Typography>
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {licenseValue.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: '30vh',width: '100%', overflow: 'auto' }}>
              <DataGridPremium
                columns={LicenseListTableColumn()}
                rows={licenseList}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                hideFooter
                disableRowGrouping
                getRowHeight={() => 'auto'}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      {showAddEditLicenseDialog ? (
        <UpdateLicense
          item={item}
          editPaymentDialogEnable={showAddEditLicenseDialog}
          editPaymentDialogUpdateState={editLicenseDialogUpdatedState}
          successMessage={handleEditSuccessMessage}
        />
      ) : null}
      {showDeleteLicenseDialog ? <DeleteLicense /> : null}
      <ApplogieSnackbar
        msg={updateUsageByIdMsg}
        type={updateUsageByIdMsgType}
        alertClose={closeSnackBar}
      />
      {addLicenseSuccessMessage && (
        <ApplogieSnackbar
          msg={addLicenseSuccessMessage}
          type="success"
          alertClose={closeUserDetailsSnackbar}
        />
      )}
      {successEditMessage && (
        <ApplogieSnackbar
          msg={successEditMessage}
          type="success"
          alertClose={closeUserDetailsSnackbar}
        />
      )}
      {deleteLicenseMessage && (
        <ApplogieSnackbar
          msg={deleteLicenseMessage}
          type="error"
          alertClose={closeUserDetailsSnackbar}
        />
      )}
    </Box>
  )
}
