import { Box, Link } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import { renderApplogieDate } from 'components/Utility'
export function OptimizationTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: true,
      hideable: false,
      width: 360,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
        <Link href={`/detail/${params.row.vendorID}`} variant='subtitle2' underline="none"> 
          <b>{params.row.vendorName}</b></Link>
        )
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
      hideable: false,
      width: 550,
      align: 'left',
    },
    {
      field: 'createDate',
      headerName: 'Create Date',
      sortable: true,
      hideable: true,
      width: 220,
      align: 'left',
      renderCell: (params: GridCellParams) => (
      <Box>{renderApplogieDate(params.row.createDate) !== null && renderApplogieDate(params.row.createDate)}</Box>
      ),
    },

    {
      field: 'overage',
      headerName: 'Overage',
      sortable: true,
      hideable: false,
      width: 220,
      align: 'left',
    },
    {
      field: 'potentialSavings',
      headerName: 'Potential Savings',
      sortable: true,
      hideable: true,
      minWidth: 180,
      align: 'left',
    },
  ]
  return columns
}
