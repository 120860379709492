import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { hideComment } from 'reduxStore/comments/Hide/hideCommentSlice'
import { HideCommentReq } from 'reduxStore/comments/Hide/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'

type Props = {
  enableHideCommentDialog: boolean
  enableHideCommentDialogUpdateState: (value: boolean) => void
  selectedCommentId: string
  selectedDialogUpdatedState: (value: string) => void
}

export const HideComment = ({
  enableHideCommentDialog,
  enableHideCommentDialogUpdateState,
  selectedCommentId,
  selectedDialogUpdatedState,
}: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const closeDialog = () => {
    setOpenDialog(false)
    enableHideCommentDialogUpdateState(false)
    selectedDialogUpdatedState('')
  }
  useEffect(() => {
    setOpenDialog(enableHideCommentDialog)
  }, [enableHideCommentDialog])

  const deleteCommentById = async () => {
    let deleteCommentByIdPayload = {
      id: Number(selectedCommentId),
      token: Token!,
    } as HideCommentReq
    await dispatch(hideComment(deleteCommentByIdPayload))
  }
  const deleteCommentByIdRes = useAppSelector((state) => state.hideComment)
  const [deleteCommentMsg, setHideCommentMsg] = React.useState('')
  useEffect(() => {
    if (deleteCommentByIdRes?.successMessage !== '') {
      closeDialog()
    }
    if (deleteCommentByIdRes?.errorMessage !== '') {
      setHideCommentMsg(deleteCommentByIdRes?.errorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommentByIdRes])

  return (
    <Dialog open={openDialog} onClose={closeDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <DialogContentText>
          <strong>Are you sure you want to hide this comment for all users?</strong>
          {deleteCommentMsg !== '' && (
            <Box py={2}>
              <Alert severity="error">{deleteCommentMsg}</Alert>{' '}
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => deleteCommentById()}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Typography>Hide Comment</Typography>
            {deleteCommentByIdRes.loading && <CircularProgress color="inherit" size={18} />}
          </Stack>
        </Button>
        <Button variant="outlined" onClick={closeDialog} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
