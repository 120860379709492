import * as React from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import UpdateUserDetails from './UpdateUserDetails/Index'
import { updateUserDetailsClearRes } from 'reduxStore/users/updateUserDetails/updateUserDetailsSlice'
import { renderApplogieDate } from 'components/Utility'

export default function UserDetail() {
  const userDetailsById = useAppSelector((state) => state.userDetailsById)
  const dispatch = useAppDispatch()
  const [showUpdateUserDetailsDialog, setShowUpdateUserDetailsDialog] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const updateDialogState = () => {
    setShowUpdateUserDetailsDialog(true)
    dispatch(updateUserDetailsClearRes())
  }

  const updateUserDetailsDialogValFromChild = (passedVal: boolean) => {
    setShowUpdateUserDetailsDialog(passedVal)
  }

  const handleSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setSuccessMessage(message)
  }

  return (
    <Box>
      {successMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(successMessage)}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" variant="filled">
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {showUpdateUserDetailsDialog ? (
        <UpdateUserDetails
          udpateUserDetailsDialogEnable={showUpdateUserDetailsDialog}
          udpateUserDetailsDialogUpdateState={updateUserDetailsDialogValFromChild}
          onSuccessUpdateUserDetailsMsg={handleSuccessMessage}
        />
      ) : null}
      <Card>
        <CardHeader
          avatar={<AccountBoxIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      User Details
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<BorderColorIcon />}
                  onClick={() => updateDialogState()}
                >
                  Edit
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Box sx={{ width: '100%', bgcolor: '#F2F2F2' }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                p={1}
                spacing={1}
              >
                  <Grid item md={6}>
                    <Typography variant="body2">First Name</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {userDetailsById.userDetails.first_name !== ''
                      ? userDetailsById.userDetails.first_name
                      : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body2">Last Name</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {userDetailsById.userDetails.last_name !== ''
                      ? userDetailsById.userDetails.last_name
                      : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body2">Email</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873',wordBreak:"break-word"}}>
                      {userDetailsById.userDetails.email}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body2">create Date</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {renderApplogieDate(userDetailsById.userDetails.create_date!)}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body2">Status</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                      {userDetailsById.userDetails.active === true ? 'Active' : 'Inactive'}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body2">Department</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#134873' }}>
                    {'userDetails' in userDetailsById
                      ? userDetailsById.userDetails !== undefined
                        ? userDetailsById.userDetails !== null
                          ? 'department' in userDetailsById.userDetails
                            ? userDetailsById.userDetails.department !== undefined
                              ? userDetailsById.userDetails.department !== null
                                ? 'name' in userDetailsById.userDetails.department
                                  ? userDetailsById.userDetails.department.name !== undefined
                                    ? userDetailsById.userDetails.department.name !== null
                                      ? userDetailsById.userDetails.department.name
                                      : 'Not Set'
                                    : 'Not Set'
                                  : 'Not Set'
                                : 'Not Set'
                              : 'Not Set'
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'
                      : 'Not Set'}
                    </Typography>
                  </Grid>
              </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
