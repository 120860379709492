import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { VendorDeptDetailsReq } from 'reduxStore/vendorDeptDetails/vendorDeptDetailsModel'
import {
  fetchVendorDeptDetails,
  updateVendorDeptDetailsClearRes,
} from 'reduxStore/vendorDeptDetails/vendorDeptDetailsSlice'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
import IndividualDeptSpend from './IndividualDeptSpend'

am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
interface FilteredDept {
  cost: number | undefined
  projected_cost: number | undefined
  dept_short_name: string | undefined
  dept_name: string | undefined
  department_id: string | undefined
  userCount?: number
}
export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}
export default function VendorDept() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const venDept = useAppSelector((state) => state.vendorDept)
  const vendorDeptUserListRes = useAppSelector((state) => state.fetchVendorDeptUserListRes)

  const [selectedItem, setSelectedItem] = useState({} as FilteredDept)
  const updateSelectedItem = (passedVal: FilteredDept) => {
    setSelectedItem(passedVal)
  }
  // Data
  const [departmentWithUserList, setDepartmentWithUserList] = useState([] as FilteredDept[])

  const [modalOpen, setModalOpen] = React.useState(false)
  const closeDialog = (passedVal: boolean) => {
    setModalOpen(passedVal)
    dispatch(updateVendorDeptDetailsClearRes())
  }

  function selectChartItem(item: FilteredDept) {
    setSelectedItem(item)
    let deptDetailsParam = {
      deptId: String(item.department_id),
      length: String(10),
      token: Token!,
    } as VendorDeptDetailsReq
    dispatch(fetchVendorDeptDetails(deptDetailsParam))
  }

  const [renewalNoRatio, setRenewalNoRatio] = useState<number | null>(null)
  useEffect(() => {
    let modifiedDeptList = [] as FilteredDept[]
    if (venDept.vendorSpends !== undefined && venDept.vendorSpends.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modifiedDeptList = venDept.vendorSpends.map((item) => {
        return {
          cost: item?.cost !== null ? Math.round(Number(item.cost)) : 0,
          projected_cost:
            item?.projected_cost !== null ? Math.round(Number(item.projected_cost)) : 0,
          dept_short_name: item?.dept_short_name !== null ? item.dept_short_name : 'Not set',
          dept_name: item?.department_name !== null ? item.department_name : 'Not set',
          department_id: item?.department_id !== null ? String(item.department_id) : 'Not Set',
        }
      })
    }
    let modifiedDeptListWithUserCount = [] as any[]
    if (vendorDeptUserListRes?.vendorDeptUserListRes?.results !== undefined) {
      if (vendorDeptUserListRes?.vendorDeptUserListRes?.results.length > 0) {
        modifiedDeptListWithUserCount = modifiedDeptList.map((itemA) => {
          const matchingItemB = vendorDeptUserListRes?.vendorDeptUserListRes?.results.find(
            (itemB: any) => itemB.dept_short_name === itemA.dept_short_name,
          )
          return {
            ...itemA,
            userCount: matchingItemB ? matchingItemB.user_count : 0,
          }
        })
      }
    }
    let highestSpend = modifiedDeptListWithUserCount.reduce(
      (max, p) => (p?.cost! > max! ? p?.cost! : max),
      modifiedDeptListWithUserCount[0]?.cost!,
    )

    let highestBudget = modifiedDeptListWithUserCount.reduce(
      (max, p) => (p?.projected_cost! > max! ? p?.projected_cost! : max),
      modifiedDeptListWithUserCount[0]?.projected_cost,
    )

    let highestVal = highestSpend > highestBudget! ? highestSpend : highestBudget

    let maxRenewalNo = Math.max(
      ...modifiedDeptListWithUserCount.map((item) => item.userCount ?? -Infinity),
    )
    if (maxRenewalNo !== -Infinity) {
      setRenewalNoRatio(Math.floor(highestVal! / maxRenewalNo))

      modifiedDeptListWithUserCount.forEach((item: any) => {
        if ('userCount' in item) {
          item.userCount *= Math.floor(highestVal! / maxRenewalNo)
        }
      })
      modifiedDeptListWithUserCount.forEach((item) => {
        if ('userCount' in item && isNaN(item.userCount!)) {
          delete item.userCount
        }
      })
    }
    setDepartmentWithUserList(modifiedDeptListWithUserCount)
  }, [venDept, vendorDeptUserListRes])
  useEffect(() => {
    let root = am5.Root.new('vendorDeptDiv')
    var modal = am5.Modal.new(root, {
      content: 'Vendor Spend by Department (Last 12 months) chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])

    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    })

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      }),
    )

    chart.children.unshift(
      am5.Label.new(root, {
        text: 'Vendor Spend by Department (Last 12 months)',
        fontSize: 20,
        fontWeight: '600',
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      }),
    )

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    )

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        //behavior: 'zoomX',
      }),
    )
    cursor.lineX.setAll({
      stroke: am5.Color.fromString('#13beda'),
      strokeWidth: 2,
    })
    cursor.lineY.set('visible', false)

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'dept_short_name',
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 10 }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ['axis'],
          animationDuration: 200,
        }),
      }),
    )

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#0a",
        min: 0, // Set minimum value to 0
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Spend to Date',
        fontSize: 12,
        fontWeight: 'bold',
        y: am5.p50,
        centerX: am5.p50,
      }),
    )

    // Budget Bar
    var series0 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Department Forecast',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'projected_cost',
        categoryXField: 'dept_short_name',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{dept_name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    series0.columns.template.setAll({
      width: am5.percent(50),
      height: am5.percent(5000),
      fillOpacity: 0.3,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    series0.data.setAll(departmentWithUserList)
    // Spend Bar
    var series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Department Spend',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'cost',
        categoryXField: 'dept_short_name',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{dept_name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    series1.columns.template.setAll({
      width: am5.percent(25),
      fillOpacity: 1,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    var series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        // name: 'Department Spend',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'userCount',
        categoryXField: 'dept_short_name',
        interactive: true,
        blur: 1,
        opacity: 0,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
        }),
        maskBullets: false,
      }),
    )
    series2.columns.template.setAll({
      fillOpacity: 0, // Adjust the opacity (0 is fully transparent, 1 is fully opaque)
      strokeOpacity: 0, // Optional: Set the top right corner radius
    })
    series2.get('tooltip')!.label.adapters.add('text', function (text: any, _target) {
      var tooltipDataItem = series2.get('tooltipDataItem')
      let a = {} as any
      a = tooltipDataItem?.dataContext
      var value: any = a?.userCount! / renewalNoRatio!
      text = `${a?.dept_name} Users: ${value}`
      return text
    })
    series2.bullets.push(function (root, _series, dataItem) {
      var circle = am5.Circle.new(root, {
        strokeWidth: 0,
        fill: am5.color(0x0d0939),
        fillOpacity: 1,
        radius: 5,
        centerX: am5.percent(50),
        centerY: am5.percent(50),
      })
      circle.events.on('click', function (ev) {
        selectChartItem(ev?.target?.dataItem?.dataContext! as FilteredDept)
        setModalOpen(true)
      })
      if (dataItem.get('valueY')! > 0) {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: circle,
        })
      }
    })

    series2.data.setAll(departmentWithUserList.filter((item: any) => item.userCount !== undefined))

    let legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.p50,
        centerX: am5.p50,
      }),
    )
    legend.valueLabels.template.setAll({
      width: 100,
      textAlign: 'right',
    })
    legend.data.setAll(chart.series.values)

    if (departmentWithUserList.length === 0) {
      modal.open()
    } else {
      modal.close()
    }

    // Add event listeners on click event
    series0.columns.template.events.on('click', function (ev: any) {
      // setSelectedItem(ev.target.dataItem.dataContext)
      selectChartItem(ev.target.dataItem.dataContext)
      setModalOpen(true)
    })
    series1.columns.template.events.on('click', function (ev: any) {
      // setSelectedItem(ev.target.dataItem.dataContext)
      selectChartItem(ev.target.dataItem.dataContext)
      setModalOpen(true)
    })
    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'MonthlyVendorSpend',
      dataSource: departmentWithUserList,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    xAxis.data.setAll(departmentWithUserList)
    series1.data.setAll(departmentWithUserList)
    chart.appear(1000, 100)
    series0.appear()
    series1.appear()
    series2.appear()
    return () => root.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentWithUserList, selectedItem])

  return (
    <React.Fragment>
      <div id="vendorDeptDiv" style={{ width: '100%', height: '550px' }} />
      <IndividualDeptSpend
        enableDialog={modalOpen}
        updateDialogState={closeDialog}
        selectedItem={selectedItem}
        updateSelectedItem={updateSelectedItem}
      />
    </React.Fragment>
  )
}
