import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  RawBody,
  updateUserDetailsBody,
} from 'reduxStore/users/updateUserDetails/updateUserDetailsModel'
import {
  updateUserDetailsById,
  updateUserDetailsClearRes,
} from 'reduxStore/users/updateUserDetails/updateUserDetailsSlice'
import { fetchUserDetailsById } from 'reduxStore/users/userDetails/userDetailsSlice'
import { UserDetailsModelReq } from 'reduxStore/users/userDetails/Model'
import { useParams } from 'react-router-dom'
import { Result } from 'reduxStore/subcription/Department/Model'
import { renderApplogieDate } from 'components/Utility'

type Props = {
  udpateUserDetailsDialogEnable?: boolean | any
  udpateUserDetailsDialogUpdateState?: boolean | any
  onSuccessUpdateUserDetailsMsg?: (message: string) => void
}

export default function UpdateUserDetails({
  udpateUserDetailsDialogEnable,
  udpateUserDetailsDialogUpdateState,
  onSuccessUpdateUserDetailsMsg,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')
  // Get the userId param from the URL.
  let { id } = useParams()
  // Call store
  const userDetailsById = useAppSelector((state) => state.userDetailsById)
  const subsdepartmentValue = useAppSelector((state) => state.subsdepartment)
  const updateUserDetailsRes = useAppSelector((state) => state.updateUserDetails)

  const [departmentValue, setDepartmentValue] = React.useState([] as any[])
  const [dialogState, setDialogState] = React.useState(false)
  const [selectedDepartment, setSelectedDepartment] = React.useState<number | null>(null)
  const [dept, setDept] = React.useState({} as Result)
  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showUpdateUserMsg, setShowUpdateUserMsg] = React.useState('')
  const [enableUpdateUserSnackbar, setEnableUpdateUserSnackbar] = React.useState(false)
  const closeUpdateUserSnackbar = () => {
    dispatch(updateUserDetailsClearRes())
    setShowUpdateUserMsg('')
  }
  const handleClose = () => {
    setDialogState(false)
    udpateUserDetailsDialogUpdateState(false)
    dispatch(updateUserDetailsClearRes())
  }

  React.useEffect(() => {
    setDialogState(udpateUserDetailsDialogEnable)
  }, [udpateUserDetailsDialogEnable])
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      'userDetails' in userDetailsById
        ? userDetailsById.userDetails !== undefined
          ? userDetailsById.userDetails !== null
            ? 'department' in userDetailsById.userDetails
              ? userDetailsById.userDetails.department !== undefined
                ? userDetailsById.userDetails.department !== null
                  ? 'name' in userDetailsById.userDetails.department
                    ? userDetailsById.userDetails.department.name !== undefined
                      ? userDetailsById.userDetails.department.name !== null
                        ? setDept(userDetailsById.userDetails.department)
                        : setDept({} as Result)
                      : setDept({} as Result)
                    : setDept({} as Result)
                  : setDept({} as Result)
                : setDept({} as Result)
              : setDept({} as Result)
            : setDept({} as Result)
          : setDept({} as Result)
        : setDept({} as Result)
    }
  }, [userDetailsById])

  React.useEffect(() => {
    if ('department' in subsdepartmentValue) {
      if ('results' in subsdepartmentValue.department) {
        if (subsdepartmentValue.department.results !== undefined) {
          if (subsdepartmentValue.department.results !== null) {
            setDepartmentValue(subsdepartmentValue?.department?.results!)
          }
        }
      }
    }
  }, [subsdepartmentValue])

  React.useEffect(() => {
    if (updateUserDetailsRes?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowUpdateUserMsg(updateUserDetailsRes?.succssMessage)
      setEnableUpdateUserSnackbar(true)
      setDialogState(false)
      onSuccessUpdateUserDetailsMsg &&
        onSuccessUpdateUserDetailsMsg(updateUserDetailsRes?.succssMessage)
    }
    if (updateUserDetailsRes?.error !== '') {
      if (updateUserDetailsRes?.error) {
        setAPIMsgType('error')
        setShowUpdateUserMsg(updateUserDetailsRes?.error)
        setEnableUpdateUserSnackbar(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserDetailsRes])

  const filterDepartment = (department: any, _e: any) => {
    setSelectedDepartment(department.id)
    setSelectedDepartment(department.id)
  }
  let userDetailsByIdBody = {
    userId: String(id),
    token: Token,
  } as UserDetailsModelReq
  const updateUserDetailsApiCall = async () => {
    let updatedbody = {
      department_id: selectedDepartment,
    } as RawBody

    let mainbody = {
      id: String(userDetailsById.userDetails.id),
      updateBody: updatedbody,
      token: Token!,
    } as updateUserDetailsBody
    await dispatch(updateUserDetailsById(mainbody))
    await dispatch(fetchUserDetailsById(userDetailsByIdBody))
    await udpateUserDetailsDialogUpdateState(false)
  }

  return (
    <Box>
      <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                User Details
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={50}
            spacing={4}
          >
            <Grid item>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Box>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="First Name"
                      variant="standard"
                      value={userDetailsById.userDetails.first_name}
                      disabled
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      value={userDetailsById.userDetails.email}
                      disabled
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Status"
                      variant="standard"
                      value={userDetailsById.userDetails.active === true ? 'Active' : 'Inactive'}
                      disabled
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <Grid item>
                  <TextField
                    id="standard-basic"
                    label="Last Name"
                    variant="standard"
                    value={userDetailsById.userDetails.last_name}
                    disabled
                  />
                </Grid>
                <Grid item mt={2}>
                  <TextField
                    id="standard-basic"
                    label="Create Date"
                    variant="standard"
                    value={renderApplogieDate(userDetailsById.userDetails.create_date!)}
                    disabled
                  />
                </Grid>
                <Grid item mt={2}>
                  <Autocomplete
                    id="dept-states"
                    disableClearable
                    options={departmentValue}
                    onChange={(event: any, newValue: any) => {
                      filterDepartment(newValue, event)
                    }}
                    value={dept}
                    getOptionLabel={(option: any) => option.name}
                    isOptionEqualToValue={(option: any, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField {...params} label="Department Name" variant="standard" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 3 }}>
          <Button onClick={updateUserDetailsApiCall} autoFocus variant="contained">
            {updateUserDetailsRes.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Save</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              <Typography>Save</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {updateUserDetailsRes?.succssMessage !== '' ? (
        <Snackbar
          open={enableUpdateUserSnackbar}
          autoHideDuration={1000}
          onClose={closeUpdateUserSnackbar}
        >
          <Alert onClose={closeUpdateUserSnackbar} severity={apiMsgType} sx={{ width: '100%' }}>
            <strong>{showUpdateUserMsg}</strong>
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}
