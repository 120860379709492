import { Box, Switch, Link } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { CustomShowMoreDetailPanelToggle } from './CustomShowMoreDetailPanelToggel'
import { useAppDispatch } from 'reduxStore/hooks'
import { updateDeactivateActiveUser } from 'reduxStore/users/toggleActive/toggleActiveSlice'

export function ActiveAccountTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: true,
      width: 280,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
            {params.row.firstName !== "" ?
              <Link href={`/userdetail/${params.row.id}`} variant='subtitle2' underline="none">
                <b>{params.row.firstName}</b></Link>
              : null}
          </Box>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: true,
      width: 280,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.id}`} variant='subtitle2' underline="none">
            <b>{params.row.lastName}</b></Link>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      width: 330,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Link href={`/userdetail/${params.row.id}`} variant='subtitle2' underline="none">
            <b>{params.row.email}</b></Link>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Active',
      sortable: true,
      hideable: false,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Switch
            checked={
              params.row.status !== null || params.row.status !== undefined
                ? params.row.status
                : false
            }
            onClick={() => {
              dispatch(updateDeactivateActiveUser(params.row.id))
            }}
          />
        )
      },
    },
    {
      field: 'accountName',
      headerName: 'Accounts',
      sortable: true,
      hideable: true,
      minWidth: 260,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (
          <Box>
            {
              params.row.accountName.length > 0 && <React.Fragment>
                {
                  <Box sx={{ overflow: "auto" }}>
                    {
                      params.row.accountName && params.row.accountName.map && params.row.accountName.map((item: any, index: number, arr: any) => {
                        if (index + 1 === arr.length) {
                          return <Link href={`/detail/${item.value}`} variant='subtitle2' underline="none">
                            <b>{item.label}</b></Link>
                        } else {
                          return <Link href={`/detail/${item.value}`} variant='subtitle2' underline="none" sx={{ mr: "2px" }}>
                            <b>{`${item.label}, `} </b></Link>
                        }
                      })
                    }
                  </Box>}
              </React.Fragment>
            }
          </Box>
        )
      },
    },
    {
      field: 'department',
      headerName: 'Department',
      sortable: true,
      hideable: true,
      minWidth: 260,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{params.row.department !== 'Not Set' ? params.row?.department : null}</Box>
      ),
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 100,
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <CustomShowMoreDetailPanelToggle id={params.id} value={params.value} />
      ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
