import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { CommentsListPayload, CommentsListRes } from 'reduxStore/comments/commentList/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  commentsList: CommentsListRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  commentsList: {} as CommentsListRes,
  error: '',
}
export const fetchCommentsList = createAsyncThunk<CommentsListRes, CommentsListPayload>(
  'fetchCommentsList/fetchCommentsList',
  (reqParam: CommentsListPayload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account/comments/?visibility=${reqParam.count}`, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const fetchCommentsListSlice = createSlice({
  name: 'fetchCommentsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCommentsList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchCommentsList.fulfilled,
      (state, action: PayloadAction<CommentsListRes>) => {
        state.loading = false
        state.commentsList = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchCommentsList.rejected, (state, action) => {
      state.loading = false
      state.commentsList = {} as CommentsListRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchCommentsListSlice.reducer
