import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridRowParams,
} from '@mui/x-data-grid-premium'
import { BreachesTableColumn } from 'components/Breaches/BreachesTableColumn'
import DataGridTableHeader from 'components/Subscripiton/DataGridTableHeader'
import React, { useEffect, useState } from 'react'
import { TableListData } from './TableListData'
import { useLocation } from 'react-router-dom'
import ExpandBreaches from 'components/Breaches/ExpandBreaches'
import { BreachesList } from './Model'

export default function BreachesTable() {
  const location = useLocation()
  const [inVisibleColumnModel, setInVisibleColumnModel] = useState<GridColumnVisibilityModel>()
  useEffect(() => {
    location?.pathname === '/dashboard' &&
      setInVisibleColumnModel({
        password: false,
        creditCard: false,
        authToken: false,
        passwordHint: false,
        securityQA: false,
      })
    // : setInVisibleColumnModel({})
  }, [location])
  function DetailPanelContent({ row }: { row: BreachesList }) {
    return <ExpandBreaches item={row} />
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  return (
    <DataGridPremium
    sx={{
      '@media print': {
       '.MuiDataGrid-main': {
           width: 'fit-content',
           fontSize: '20px',
           height: 'fit-content',
           overflow: 'visible',
            },
           marginBottom: 100,
           },
           '& .MuiDataGrid-footerContainer': {
             height: '0px',
             minHeight: '0px',
         }, 
       }}
      columns={BreachesTableColumn()}
      rows={TableListData()}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={location.pathname === '/dashboard' ? undefined : getDetailPanelContent}
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: 'vendorName', sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: DataGridTableHeader,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooterRowCount
      hideFooterSelectedRowCount
      columnVisibilityModel={inVisibleColumnModel}
      density={location.pathname === '/dashboard' ? 'compact' : 'comfortable'}
      disableRowGrouping
      localeText={{ noRowsLabel: 'No Breaches available' }}
    />
  )
}
