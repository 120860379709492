import React from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { UserListTableColumn } from './UserListTableColumn'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
import { AllSubscriptionDetailsUserList } from './AllSubscriptionDetailsUserList'

export default function UserLists() {
  const customerPaymentList = useAppSelector((state) => state.customerPaymentList)
  const accountUserDetailsById = useAppSelector((state) => state.accountUserDetailsById)

  return (
    <DataGridPremium
      columns={UserListTableColumn()}
      rows={AllSubscriptionDetailsUserList()}
      sx={{
        '@media print': {
         '.MuiDataGrid-main': {
             width: 'fit-content',
             fontSize: '20px',
             height: 'fit-content',
             overflow: 'visible',
              },
             marginBottom: 100,
             },
             '& .MuiDataGrid-footerContainer': {
               height: '0px',
               minHeight: '0px',
           }, 
         }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'firstName', sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: MUIDDCustomToolbar,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooterRowCount
      hideFooterSelectedRowCount
      disableRowGrouping
      disableAggregation
      disableRowSelectionOnClick
      loading={accountUserDetailsById.loading || customerPaymentList?.loading}
      rowHeight={35}
    />
  )
}
